"use strict";

require("core-js/modules/es.array.push.js");
require("core-js/modules/es.array.unshift.js");
// this is a tool for finding the right node in a data structure by its path
//
// a path, here, is an expession of this form:
//
// path: one or more <step>s, joined by "/"
//
// step:
//    - a <key>, optionally followed by: "#" <id>
//
// a read-world example of a path is:
//
//    package_data/sections#cisvx932j00015bzsksfb6heq/rows#cisw6ub3k00013k5rzx869i63/manufacturer
//
// here we assume that package_data/sections points to an array, and that is an array of objects, and
// there must be an object in that array, with id property, equal to "cisvx932j00015bzsksfb6heq"...

function walkThrough(object, path, force_create) {
  let a = path.split("/");
  for (let i = 0, n = a.length; i < n; ++i) {
    let res = a[i].split("#");
    let k = res[0],
      id = res[1];
    if (object && k in object) {
      object = object[k];
    } else if (force_create) {
      object = object[k] = id ? [{
        id
      }] : {};
    } else {
      console && console.log("walkThrough k not in object", k, object);
      return null;
    }
    if (id) {
      let node = null;
      for (let j = 0, l = object.length; j < l; ++j) {
        if (object[j].id == id) {
          node = object[j];
          break;
        }
      }
      if (node) {
        object = node;
        continue;
      }
      console && console.log("walkThrough id not found", id, object);
      return null;
    }
  }
  return object;
}

/**
Set a single key in the object's data (specified by the path) to the given value.

@method set_key
@for api.object
@param {PathExpression} path path of the key to change
@param {} value the new value of the key
@return promise, use with
  .done(function(data, status){...})
  .fail(function(xhr, status, err){...})

*/
function set_key(op_details, delay, undo) {
  let self = this;
  let dfd = $.Deferred();
  let p = dfd.promise();
  if (!op_details) {
    msg = "mlac: set_key: op_details undefined";
    dfd.reject(null, null, msg);
    console.error(msg);
    return p;
  }
  let failWithMsg = function (msg) {
    dfd.reject(null, null, msg);
    if (arguments[1]) {
      console.error.apply(null, arguments);
    } else {
      console.log(msg);
    }
    return p;
  };
  console.log("op_details:", op_details);
  let {
    omitSocket,
    undo_msg,
    no_undo
  } = op_details;
  delete op_details.omitSocket;
  delete op_details.undo_msg;
  delete op_details.no_undo;
  let msg,
    stash = [],
    save_undo = !no_undo && this.stash && !undo;
  let skk = Object.keys(op_details),
    path = skk && skk[0];
  if (!path) return failWithMsg("No path");
  let changed = false;
  skk.forEach(function (pp) {
    let _path = pp.split("/"),
      rest = _path.pop().split("#"),
      key = rest[0],
      id = rest[1];

    // WTF? what is id?
    if (id) return failWithMsg("invalid path");
    let value = op_details[pp];
    let defVal = "string" == typeof value ? "" : null;
    let node;

    // simple, one-segment path? i.e. top level property in the data
    if (key && !_path.length) {
      // set the value directly
      node = this.data;

      // multi-segment path? use walkThrough()
    } else {
      node = walkThrough(this.data, _path.join("/"));
      if (node === null || node === undefined) return failWithMsg("Node at path " + pp + " not found", "\ndata:", this.data);
    }
    if (!_.isEqual(node[key], value)) {
      let prevVal = _.cloneDeep(node[key]);
      save_undo && stash.push({
        path: pp,
        data: prevVal !== undefined ? prevVal : defVal
      });
      node[key] = value;
      changed = true;
      this.set_key_addon({
        pp,
        _path,
        key,
        id,
        node,
        value
      });
    }
  }.bind(this));
  let sk_timeout = this.sk_timeout_id[path];
  if (sk_timeout) clearTimeout(sk_timeout);
  if (!changed) {
    this.clog("/set_key: no value changed", {
      op_details
    });
    dfd.resolve(null, "notmodified");
    return p;
  }
  if (this.api.socketId && !omitSocket) {
    op_details.omitSocket = this.api.socketId;
  }

  // send the set_key op to server with arbitrary milliseconds delay
  delay = delay || 0;
  this.sk_timeout_id[path] = setTimeout(function () {
    this.op('set_key', op_details).done((data, status) => {
      console.log("stash:", stash);
      console.log("self.stash:", self.stash);
      if (save_undo) self.stash.push({
        op: "set_key",
        data: stash.reverse(),
        msg: undo_msg
      });
      if (save_undo || undo) self.undo_available_change();
      dfd.resolve(data, status);
    }).fail((xhr, status, err) => {
      dfd.reject(xhr, status, err);
    });
    sk_timeout = 0; // anton: not a reference i think
    delete this.sk_timeout_id[path];
  }.bind(this), delay);

  // local change immediately
  this.emit("change", this.data);
  return p;
}

/**
Set a object or array in the object's data (specified by the path) to the given value.

@method set_branch
@for api.object
@param {PathExpression} path path of the object to change
@param {} value (object or array) the new value of the object
@return promise, use with
  .done(function(data, status){...})
  .fail(function(xhr, status, err){...})

*/
function set_branch(op_details, undo) {
  let dfd = $.Deferred(),
    p = dfd.promise();
  let {
    no_undo
  } = op_details;
  delete op_details.no_undo;
  let data = Object.assign({}, this.data),
    path = Object.keys(op_details)[0],
    node = walkThrough(data, path);
  if (node === null || node === undefined) {
    let msg = "Node at path " + path + " not found";
    dfd.reject(null, null, msg);
    console.error(msg, "\ndata:", data);
    return p;
  }
  let stash = !no_undo && this.stash && !undo && {
    op: "set_bransh",
    details: op_details,
    data: _.cloneDeep(node) || null
  };
  for (let k in node) delete node[k];
  $.extend(node, $.extend(true, Array.isArray(op_details[path]) ? [] : {}, op_details[path]));
  console.log("set_branch", node);
  this.op('set_branch', op_details).done((data, status) => {
    if (stash) this.stash.push(stash);
    if (stash || undo) this.undo_available_change();
    dfd.resolve(data, status);
  }).fail((xhr, status, err) => {
    dfd.reject(xhr, status, err);
  });
  // local change immediately
  this.emit("change", data);
  return p;
}
function set_test(path, value) {
  let _path = path.split("/"),
    rest = _path.pop().split("#"),
    key = rest[0],
    id = rest[1];

  // WTF? what is id?
  if (id) return console.error("invalid path", path);

  // simple, one-segment path? i.e. top level property in the data
  if (key && !_path.length) {
    // set the value directly
    this.test_data[key] = value;

    // multi-segment path? use walkThrough()
  } else {
    let node;
    node = walkThrough(this.test_data, _path.join("/"), true); //create keys if absent

    if (node === null || node === undefined) return console.error("Node at path " + path + " not found", "\ndata:", this.test_data);
    node[key] = value;
  }
  this.apply_test_data();
}

/**
Remove a list item, specified by the path, from the object's data.

@method rem_li
@for api.object
@param {PathExpression} path path of the key to remove
@param {Object} item the item to be removed
@return promise, use with
  .done(function(data, status){...})
  .fail(function(xhr, status, err){...})

*/
function rem_li(op_details, undo) {
  let {
    no_undo
  } = op_details;
  delete op_details.no_undo;
  if (!Array.isArray(op_details)) {
    op_details = [op_details];
  }
  let data = Object.assign({}, this.data),
    msg,
    stash = [],
    save_undo = !no_undo && this.stash && !undo;
  let dfd = $.Deferred();
  let p = dfd.promise();
  for (let i = 0, l = op_details.length; i < l; i++) {
    let op = op_details[i];
    if (!op) {
      msg = "Operation at index " + i + " not found";
      dfd.reject(null, null, msg);
      console.error(msg);
      return p;
    }
    let ops = Object.keys(op);
    for (let o = 0, opslen = ops.length; o < opslen; o++) {
      let path = ops[o],
        _path = path.split("/"),
        rest = _path.pop().split("#"),
        key = rest[0],
        id = rest[1];
      let node = walkThrough(data, _path.join("/"));
      if (node === null || node === undefined || node[key] === undefined) {
        msg = "Node at path " + path + " not found";
        dfd.reject(null, null, msg);
        console.error(msg, "\npath:", path, "\nid:", id, "\ndata:", data);
        return p;
      }
      let idx = null;
      for (let j = 0, nl = node[key].length; j < nl; ++j) {
        if (node[key][j].id == id) {
          idx = j;
          break;
        }
      }
      if (idx === null) {
        msg = "node at path " + path + " not found";
        dfd.reject(null, null, msg);
        console.log(msg);
        return p;
      }
      save_undo && stash.push({
        path,
        key,
        idx,
        data: _.cloneDeep(node[key].splice(idx, 1))
      });

      //node[key].splice(idx, 1);
      this.rem_li_addon({
        node,
        path
      });
      Object.keys(this.sk_timeout_id).forEach(skp => {
        if (skp.indexOf(path) == 0) {
          clearTimeout(this.sk_timeout_id[skp]);
          delete this.sk_timeout_id[skp];
        }
      });
    }
  }
  this.emit("change", data);
  this.op('rem_li', op_details).done((data, status) => {
    if (save_undo) this.stash.push({
      op: "rem_li",
      data: stash.reverse()
    });
    if (save_undo || undo) this.undo_available_change();
    dfd.resolve(data, status);
  }).fail((xhr, status, err) => {
    dfd.reject(xhr, status, err);
  });
  return p;
}

/**
Add a given item to a list in the object's data, specified by the path.

@method add_li
@for api.object
@param {PathExpression} path of the array to add item to
@param {Object|String} value the list item to add
@return promise, use with
  .done(function(data, status){...})
  .fail(function(xhr, status, err){...})

*/

function add_li(op_details, undo) {
  let {
    omitSocket,
    undo_msg,
    no_undo
  } = op_details;
  delete op_details.omitSocket;
  delete op_details.undo_msg;
  delete op_details.no_undo;
  let bto_top = op_details.to_top;
  delete op_details.to_top;
  let bafter = op_details.after;
  delete op_details.after;
  if (!Array.isArray(op_details)) {
    op_details = [op_details];
  }
  let data = Object.assign({}, this.data),
    msg,
    stash = [],
    save_undo = !no_undo && this.stash && !undo;
  let dfd = $.Deferred();
  let p = dfd.promise();
  for (let i = 0, l = op_details.length; i < l; i++) {
    let op = _.cloneDeep(op_details[i]);
    if (!op) {
      msg = "Operation index " + i + " not found";
      dfd.reject(null, null, msg);
      console.error(msg);
      return p;
    }
    let to_top = i ? op.to_top : op.to_top || bto_top;
    let after = i ? op.after : op.after || bafter;
    delete op.to_top;
    delete op.after;
    let path = Object.keys(op)[0];
    let node = walkThrough(data, path);
    if (node === null || node === undefined) {
      msg = "Node at path " + path + " not found";
      dfd.reject(null, null, msg);
      console.error(msg, "\ndata:", data);
      return p;
    }
    let val = op[path];
    save_undo && stash.push({
      path,
      data: val
    });
    if (!after) {
      to_top ? node.unshift(val) : node.push(val);
    } else {
      let idx = _.findIndex(node, {
        id: after
      });
      idx > -1 ? node.splice(idx + 1, 0, val) : node.push(val);
    }
    this.add_li_addon({
      node,
      path,
      value: val
    });
  }
  this.emit("change", data);
  if (omitSocket && this.api.socketId || bto_top || bafter) {
    let od = {
      data: op_details
    };
    if (omitSocket) od.omitSocket = this.api.socketId;
    if (bto_top) od.to_top = true;
    if (bafter) od.after = bafter;
    op_details = od;
  }
  console.log("omitSocket:", omitSocket, "op_details.omitSocket:", op_details.omitSocket);
  this.op('add_li', op_details).done((data, status) => {
    if (save_undo) this.stash.push({
      op: "add_li",
      data: stash.reverse(),
      msg: undo_msg
    });
    if (save_undo || undo) this.undo_available_change();
    dfd.resolve(data, status);
  }).fail((xhr, status, err) => {
    dfd.reject(xhr, status, err);
  });
  return p;
}

/**
Move an item in the object's data from one point to another, within the same data
structure.

In case of `position` being `"append"`, the `to` parameter points to a list, which
the item being moved must be appended to.

@method move_li
@for api.object
@param {Object} with keys
  from {PathExpression} path of the item to move
  position {String} `"before"` or `"after"` or `"append"`
  to {PathExpression} destination path
@param {Boolean} flag the op is undo
@return promise, use with
  .done(function(data, status){...})
  .fail(function(xhr, status, err){...})


*/

function move_li(opts, undo) {
  let {
    from,
    position,
    to,
    undo_msg,
    no_undo
  } = opts;
  let data = Object.assign({}, this.data),
    msg,
    stash = {},
    save_undo = !no_undo && this.stash && !undo;
  let dfd = $.Deferred(),
    p = dfd.promise();
  if (!(from && to && (position == "after" || position == "before" || position == "append"))) {
    msg = "malformed params";
    dfd.reject(null, null, msg);
    console.error(msg, "\nfrom:", from, "\nto:", to, "\nrelative_position:", relative_position);
    return p;
  }
  if (from == to) {
    dfd.resolve(data, "OK");
    return p;
  }
  let _paths = from.split("/"),
    rests = _paths.pop().split("#"),
    _pathd = to.split("/"),
    restd = _pathd.pop().split("#");
  let src = {
    path: from,
    _path: _paths,
    field: _paths[0],
    key: rests[0],
    id: rests[1]
  };
  let dst = {
    path: to,
    _path: _pathd,
    field: _pathd[0],
    key: restd[0],
    id: restd[1],
    delta: position == "after" ? 1 : 0,
    append: position == "append" ? 1 : 0
  };
  if (src.field != dst.field) {
    msg = "src and dst field does not match";
    dfd.reject(null, null, msg);
    console.error(msg, "\nsrc:", src.field, "\ndst:", dst.field);
    return p;
  }
  let farr = [src, dst],
    fl = farr.length,
    op_details = {};
  for (let i = 0; i < fl; ++i) {
    if (!farr[i].id && !farr[i].append) {
      msg = "invalid path";
      dfd.reject(null, null, msg);
      console.log(msg);
      return p;
    }
    farr[i].node = walkThrough(data, farr[i]._path.join("/"));
    let {
      node,
      key,
      id
    } = farr[i];
    if (node === null || node === undefined || node[key] === undefined) {
      msg = "Node at path " + farr[i].path + " not found";
      dfd.reject(null, null, msg);
      console.error(msg, "\ndata:", data);
      return p;
    }
    let idx = null;
    if (!farr[i].append) {
      for (let j = 0, nl = node[key].length; j < nl; ++j) {
        if (node[key][j].id == id) {
          idx = j;
          break;
        }
      }
      if (idx === null) {
        msg = "Node at path " + farr[i].path + " not found";
        dfd.reject(null, null, msg);
        console.error(msg, "\ndata:", data);
        return p;
      }
    }
    if (i == 0) {
      // source
      src.obj = node[key].splice(idx, 1);
      op_details.from = from;
      if (save_undo) {
        if (idx < node[key].length) {
          stash.position = "before";
          stash.to = src.path.replace(/#[^#]+$/, "#" + node[key][idx].id);
        } else {
          stash.position = "append";
          stash.to = src.path.replace(/#[^#]+$/, '');
        }
      }
    } else if (i == 1) {
      // destination
      if (dst.append) {
        while (src.obj.length) {
          node[key].push(src.obj.pop());
        }
        if (save_undo) {
          stash.from = dst.path + "#" + src.id;
        }
      } else {
        idx += dst.delta;
        while (src.obj.length) {
          node[key].splice(idx, 0, src.obj.pop());
        }
        if (save_undo) {
          stash.from = dst.path.replace(/#[^#]+$/, "#" + src.id);
        }
      }
      op_details["put_" + position] = to;
    }
    this.move_li_addon({
      node,
      path: farr[i].path
    });
  }
  this.emit("change", data);
  this.op('move_li', op_details).done((data, status) => {
    if (save_undo) this.stash.push({
      op: "move_li",
      data: stash,
      msg: undo_msg
    });
    if (save_undo || undo) this.undo_available_change();
    dfd.resolve(data, status);
  }).fail((xhr, status, err) => {
    dfd.reject(xhr, status, err);
  });
  return p;
}
function ro_wrp(fn) {
  return function () {
    if (this.is_id_ro(this.req_id)) {
      this.clog("ro id => pretending");
      this.emit("change", this.data);
      this.api.emit("op-fail", {
        status: "FAIL",
        err: "ro object"
      });
      return;
    }
    return fn.apply(this, arguments);
  };
}
function no_op() {}
module.exports = {
  walkThrough: walkThrough,
  set_key: ro_wrp(set_key),
  set_key_addon: no_op,
  set_branch: ro_wrp(set_branch),
  set_test: set_test,
  add_li: ro_wrp(add_li),
  add_li_addon: no_op,
  move_li: ro_wrp(move_li),
  move_li_addon: no_op,
  rem_li: ro_wrp(rem_li),
  rem_li_addon: no_op
};