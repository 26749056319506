"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CollectionObject = void 0;
exports.default = api_collection;
require("core-js/modules/es.array.push.js");
var _object = require("./object");
/**
BuildSite Client-Side JavaScript API v0.3

@module buildsite.api

*/

/*********  api.collection class  *********/

/**
A collection is a list of objects. It has the same server-polling features
as {{#crossLink "api.object"}}{{/crossLink}} has.

When / if the list data is changed, the `change` event will trigger.

Usage:

    // get my packages list
    var packages = api.collection("package");
    packages.on( "open",   load_cb );   // initial loading
    packages.on( "change", change_cb ); // any change to the list
    packages.on( "mlac.error",  error_cb );  // access denied and other errors
    // actually request data from the server
    packages.open() // actually send a request to the server.
    .done(function(data){...})
    .fail(function({ message: ..., status: ..., err: ... }, data){...})

    // shared packages list
    var sp = api.collection("package","shared");
    sp.open() // get it
    .done(function(data){...})
    .fail(function({ message: ..., status: ..., err: ... }, data){...})

    // list of shared files
    var sf = api.collection("file","shared");
    sf.open()
    .done(function(data){...})
    .fail(function({ message: ..., status: ..., err: ... }, data){...})

@class api.collection
@extends api.object
@for api
@static

*/

class CollectionObject extends _object.BaseObject {
  constructor(api, type_of, id, op, req_opts) {
    id = id || "_";
    super(api, "collection", id, null, req_opts);
    this.collection_of = type_of;
    this._op = op || "list";
    this.whoami_value = "[collection] " + type_of + "/" + id;
  }
  open() {
    return this.open_factory("collection", function () {
      return $.extend(true, {
        op: this._op,
        type: this.collection_of,
        id: this.req_id,
        ifModified: false
      }, this.req_opts);
    })(...arguments);
  }
  get_snapshot() {
    return this.get_snapshot_factory("collection", () => {
      return {
        op: this._op,
        type: this.collection_of,
        id: this.req_id,
        ifModified: !this.disable_if_modified
      };
    })();
  }
  status_change(object_type, id, status_check_fn) {
    let item,
      list = this.data,
      status_to;
    if (["file", "folder", "package", "project", "rfi"].indexOf(object_type) == -1) {
      this.emit("mlac.error", {
        err: "delete not supported for collection type"
      });
      return;
    }
    if (list) {
      //op can be called on not loaded collection
      item = _.find(list, li => {
        let _type = li.__type,
          _thislist = _type == object_type || _type == "folder" && object_type == "file";
        return li.__id == id && _thislist;
      });
      if (!item) {
        this.emit("mlac.error", {
          err: "item not found in collection"
        });
        return;
      }
      status_to = status_check_fn(item);
      if (!status_to) {
        this.emit("mlac.error", {
          err: "item status bad"
        });
        return;
      }
    }
    if (item && list) {
      item.status = status_to;
      this.emit("change", this.data);
    }
    return this.api[item ? item.__type : object_type](id);
  }
  delete(id) {
    let obj = this.status_change(this.collection_of, id, i => {
      return !i.status || i.status == "live" ? "deleted" : null;
    });
    if (!obj) return;
    obj.delete();
    obj.close();
    return;
  }
  undelete(object_type, id) {
    let obj = this.status_change(object_type, id, i => {
      return !i.status || i.status == "deleted" ? "live" : null;
    });
    if (!obj) return;
    obj.undelete();
    obj.close();
    return;
  }
  hide(object_type, id) {
    let obj = this.status_change(object_type, id, i => "hidden");
    if (!obj) return;
    obj.delete({
      hide: 1
    });
    obj.close();
    return;
  }
  have(id, object_type) {
    let result = false;
    if (this.data && id) {
      result = !!_.find(this.data, el => {
        return el.__id == id && (!object_type || el.__type == object_type);
      });
    }
    return result;
  }
  push(data) {
    this.data.push(data);
  }
  element_set_key(elem_id, op_details) {
    if (!_.isArray(this.data)) return;
    if (!op_details || !_.keys(op_details).length) {
      console.error("mlac: collection: element_set_key: empty op_details", op_details);
      return;
    }
    let elem_list = this.data.filter(el => el.__id == elem_id);
    if (elem_list.length > 1) {
      console.error("mlac: collection: element_set_key: more than 1 id", elem_id);
      return;
    }
    if (!elem_list.length) return;
    let no_emit_change = op_details.no_emit_change;
    delete op_details.no_emit_change;
    let d = elem_list[0];
    let skk = _.keys(op_details);
    skk.forEach(path => {
      let _path = path.split("/"),
        rest = _path.pop().split("#"),
        key = rest[0];

      // simple, one-segment path? i.e. top level property in the data
      if (key && !_path.length) {
        // set the value directly
        d[key] = op_details[path];

        // multi-segment path? use walkThrough()
      } else {
        let node = this.walkThrough(d, _path.join("/"));
        if (node === null || node === undefined) {
          console.error("mlac: collection: element_set_key: Node not found", path);
          return;
        }
        node[key] = op_details[path];
      }
    });
    if (!no_emit_change) this.emit("change", this.data);
    return;
  }
  element_del(elem_id, opts) {
    if (!_.isArray(this.data)) return;
    this.data = _.filter(this.data, el => el.__id != elem_id);
    if (!(opts || {}).no_emit_change) this.emit("change", this.data);
    return;
  }
  pubBaseUrl() {
    return [this.api.base, this.type].join('/');
  }
  pubGetRooms(data) {
    if (!data) return null;
    if ('logitem' == this.collection_of) return [`/${this.collection_of}//list project:${this.req_id}`];
    if ('rfi' == this.collection_of && this.req_id != '_') {
      return [`/${this.collection_of}//list project:${this.req_id}`];
    }
    let user = buildsite.currentUser();
    let u = _.get(user, 'data') || {},
      can = _.get(u, 'company.can', {});
    let rooms = [];
    if (u.user_id) rooms.push(`/${this.collection_of}//list user:${u.user_id}`);
    if (can.admin) rooms.push(`/${this.collection_of}//list user:admin`);
    if (u.company_id && ['file', 'deleted'].indexOf(this.collection_of) !== -1) {
      rooms.push(`/file//list company:${u.company_id}`);
    }

    //console.log("pubGetRooms:", {rooms, u, o: this});
    return rooms;
  }
}

/*********  api.collection() method  *********/

/**
Creates and returns a {{#crossLink "api.collection"}}{{/crossLink}} instance, which
represents a list of data records on the server.

Usage:

    // get my packages list
    var packages = api.collection("package");
    packages.on( "open",   load_cb );   // initial loading
    packages.on( "change", change_cb ); // any change to the list
    packages.on( "mlac.error",  error_cb );  // access denied and other errors
    // actually request data from the server
    packages.open() // actually send a request to the server.
    .done(function(data){...})
    .fail(function({ message: ..., status: ..., err: ... }, data){...})

    // shared packages list
    var sp = api.collection("package","shared");
    sp.open() // get it
    .done(function(data){...})
    .fail(function({ message: ..., status: ..., err: ... }, data){...})

    // list of shared files
    var sf = api.collection("file","shared");
    sf.open()
    .done(function(data){...})
    .fail(function({ message: ..., status: ..., err: ... }, data){...})

@method collection
@for api
@param {String} type
@param {String} [scope] Accepted value: `"shared"`. Will get
you all items of the type `type` shared with you.
@return api.collection instance

*/
exports.CollectionObject = CollectionObject;
function api_collection(type_of, id, op, req_opts) {
  let api = this;
  let c = new CollectionObject(api, type_of, id, op, req_opts);
  return c;
}