"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
require("core-js/modules/es.array.push.js");
var _validator = require("validator");
var _common = require("../../../ui-components/common");
var _prompt = _interopRequireDefault(require("../../../ui-components/prompt"));
var _countriesMin = _interopRequireDefault(require("countries-list/countries.min.json"));
var _constants = require("../../../api/constants");
(function () {
  const common = {};
  common.isEmail = _validator.isEmail;
  common.isCreditCard = _validator.isCreditCard;
  common.default_country_us = (_countriesMin.default.US || {}).name;
  common.CountryList = ['US', 'CA'].map(x => (_countriesMin.default[x] || {}).name).concat(Object.keys(_countriesMin.default).filter(function (x) {
    return x !== 'US' && x !== 'CA';
  }).map(function (k) {
    return _countriesMin.default[k].name;
  }).sort(function (a, b) {
    return a.localeCompare(b);
  }));

  /*
   * Source: http://www.mapsofworld.com/usa/states/
   */
  common.USStatesList = {
    stateByName: {
      'Alabama': 'AL',
      'Alaska': 'AK',
      'Arizona': 'AZ',
      'Arkansas': 'AR',
      'California': 'CA',
      'Colorado': 'CO',
      'Connecticut': 'CT',
      'Delaware': 'DE',
      'Florida': 'FL',
      'Georgia': 'GA',
      'Hawaii': 'HI',
      'Idaho': 'ID',
      'Illinois': 'IL',
      'Indiana': 'IN',
      'Iowa': 'IA',
      'Kansas': 'KS',
      'Kentucky': 'KY',
      'Louisiana': 'LA',
      'Maine': 'ME',
      'Maryland': 'MD',
      'Massachusetts': 'MA',
      'Michigan': 'MI',
      'Minnesota': 'MN',
      'Mississippi': 'MS',
      'Missouri': 'MO',
      'Montana': 'MT',
      'Nebraska': 'NE',
      'Nevada': 'NV',
      'New Hampshire': 'NH',
      'New Jersey': 'NJ',
      'New Mexico': 'NM',
      'New York': 'NY',
      'North Carolina': 'NC',
      'North Dakota': 'ND',
      'Ohio': 'OH',
      'Oklahoma': 'OK',
      'Oregon': 'OR',
      'Pennsylvania': 'PA',
      'Rhode Island': 'RI',
      'South Carolina': 'SC',
      'South Dakota': 'SD',
      'Tennessee': 'TN',
      'Texas': 'TX',
      'Utah': 'UT',
      'Vermont': 'VT',
      'Virginia': 'VA',
      'Washington': 'WA',
      'Washington, D.C.(DC)': 'DC',
      'West Virginia': 'WV',
      'Wisconsin': 'WI',
      'Wyoming': 'WY'
    },
    stateByAbbr: {
      'AL': 'Alabama',
      'AK': 'Alaska',
      'AZ': 'Arizona',
      'AR': 'Arkansas',
      'CA': 'California',
      'CO': 'Colorado',
      'CT': 'Connecticut',
      'DE': 'Delaware',
      'FL': 'Florida',
      'GA': 'Georgia',
      'HI': 'Hawaii',
      'ID': 'Idaho',
      'IL': 'Illinois',
      'IN': 'Indiana',
      'IA': 'Iowa',
      'KS': 'Kansas',
      'KY': 'Kentucky',
      'LA': 'Louisiana',
      'ME': 'Maine',
      'MD': 'Maryland',
      'MA': 'Massachusetts',
      'MI': 'Michigan',
      'MN': 'Minnesota',
      'MS': 'Mississippi',
      'MO': 'Missouri',
      'MT': 'Montana',
      'NE': 'Nebraska',
      'NV': 'Nevada',
      'NH': 'New Hampshire',
      'NJ': 'New Jersey',
      'NM': 'New Mexico',
      'NY': 'New York',
      'NC': 'North Carolina',
      'ND': 'North Dakota',
      'OH': 'Ohio',
      'OK': 'Oklahoma',
      'OR': 'Oregon',
      'PA': 'Pennsylvania',
      'RI': 'Rhode Island',
      'SC': 'South Carolina',
      'SD': 'South Dakota',
      'TN': 'Tennessee',
      'TX': 'Texas',
      'UT': 'Utah',
      'VT': 'Vermont',
      'VA': 'Virginia',
      'WA': 'Washington',
      'DC': 'Washington, D.C.(DC)',
      'WV': 'West Virginia',
      'WI': 'Wisconsin',
      'WY': 'Wyoming'
    }
  };
  common.USStatesListDropdown = Object.keys(common.USStatesList.stateByAbbr).map(function (k) {
    return {
      value: k,
      label: common.USStatesList.stateByAbbr[k]
    };
  });
  common.USStatesAbbrListDropdown = Object.keys(common.USStatesList.stateByName).map(function (k) {
    return {
      value: k,
      label: common.USStatesList.stateByName[k]
    };
  });
  common.ProfileFields = ['street', 'street2', 'city', 'state', 'postal_code', 'country'];
  common.ProfileAddressList = function (profile) {
    let address = [],
      isUSA = ((profile || {}).country || 'United States') == 'United States';
    common.ProfileFields.forEach(function (key) {
      if (profile[key]) {
        let data;
        switch (key) {
          case 'city':
            data = profile[key];
            if (profile['state'] && isUSA) {
              data += ', ' + profile['state'];
            }
            break;
          case 'state':
            if (!profile['city'] || !isUSA) {
              data = profile[key];
            }
            break;
          default:
            data = profile[key];
        }
        if (data) address.push(data);
      }
    });
    return address;
  };
  common.companyTypes = ['General Contractor', 'Subcontractor', 'Architect or Engineer', 'Distributor', 'Manufacturer', 'Other'];
  common.is_password_valid = function (p) {
    return p && p.length > 7 && p.replace(/\s/g, '') == p && p.search(/\d/) > -1 && p.search(/[A-Z]/) > -1;
  };
  common.unseteuid = function (e) {
    (0, _common.pr)(e);
    let user = this.props.user || {},
      userName = user.name || user.email_address || 'user';
    const done = function (password) {
      window._spinner && window._spinner.emit('on', {
        className: 'dark',
        text: 'Re-login admin user...'
      });
      return buildsite.user().op('unseteuid', {
        password: password
      }).then(data => {
        if (data.status && data.status === 'OK') {
          window.location = user.ruser.company_id ? '/at/users' : '/app/settings';
          return;
        }
      }).fail((jqXHR, status, error) => {
        if ((status || '') !== 'FAILHANDLED') {
          alertify.error(error || `Can't log out of ${userName}'s account!`);
        }
      }).always(() => {
        window._spinner && window._spinner.emit('off');
      });
    };
    window.modal(_prompt.default, {
      modalType: 'narrow-modal info-modal logout-modal',
      header: `Log out of ${userName}'s account`,
      label: 'Please enter admin user password:',
      okBtn: 'Logout',
      forceInput: true,
      inputType: 'password',
      okClick: done
    });
  };
  common.notEmpty = function (str) {
    return str && str.trim() ? true : false;
  };
  common.userErrorMessage = function (error) {
    let message = "Unexpeced error";
    const code = error[0] && error[0].code || '';
    switch (code) {
      case _constants.ERROR_CODES.INPUT_ACCOUNT_EXISTS:
        message = "It looks like an account already exists with the email address you've entered. " + "Please check your spelling and use a different email account if necessary.";
        break;
      case _constants.ERROR_CODES.NOT_ENOUGH_CAN:
        message = "It looks like you have exceeded active users limit allowed by your subscription. " + "Buy Additional Team Members add on or deactivate some users and try again.";
        break;
      case _constants.ERROR_CODES.INPUT_BAD_VALUE:
        message = "It looks like you have entered malformed email address. Please check and try again.";
        break;
      case '':
        break;
      default:
        message = error[0].field ? ` (${error[0].field})` : "";
        message = `Error: ${error[0].code}${message}!`;
    }
    return message;
  };

  // re-export
  common.planSpecs = _common.planSpecs;
  common.pr = _common.pr;
  common.prettyName = _common.prettyName;
  common.getPricingUrl = _common.getPricingUrl;

  // Node.js
  if (typeof module === 'object' && module.exports) {
    module.exports = common;
  }
  // AMD / RequireJS
  else if (typeof define === 'function' && define.amd) {
    define([], function () {
      return common;
    });
  }
  // included directly via <script> tag
  else {
    root.SettingsCommon = common;
  }
})();