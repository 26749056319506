"use strict";

require("core-js/modules/es.error.cause.js");
require("core-js/modules/es.array.push.js");
require("core-js/modules/es.object.has-own.js");
/* eslint-disable indent */
const rules = require('./package-locking-rules.json');
const rulesSR = require('./package-locking-rules-sections-rows.json');
const rulesSM = require('./package-send-menu-rules.json');
const caseToMode = require('./row-section-case-to-mode.json');
const srModes = require('./row-section-modes.json');
const tags = require('./tags.json');
const feedbackSchemes = require("./feedback-schemes.json");
const rulesRfi = require('./rfi-locking-rules.json');
const {
  logitem_section_number
} = require('./spec_standards');
const {
  isDeleted,
  is_user_registered,
  prettyName,
  capitalize,
  cleanPath
} = require('./primal');
const reviewerRoles = ["primary", "secondary"];
const {
  ACCESS_LEVEL,
  PACKAGE_CLASS
} = require('../api/constants');
function clog() {
  let wbc = typeof window != "undefined" && window.buildsite && window.buildsite.config,
    clb = wbc && wbc.clientLogging && wbc.clientLogging.business,
    serverside_debug_logging = typeof process != "undefined" && process.env && process.env.BUSINESS_LOGGING;
  if (typeof window == "undefined" && serverside_debug_logging || clb) {
    console.log.apply(this, arguments);
  }
  return false;
}
function user_role(pkg) {
  if (!pkg) throw new Error("user_role: no package");
  let reg = pkg.registry;
  // TODO what to return ?
  if (!reg) return null;
  let fl = reg.flow_level || 0;
  if (fl == 0) return 'creator';
  if (fl < 0) {
    if (reg.flow_down) return 'intermediate';
    return 'assignee';
  }
  // reg.flow_level > 0
  return 'reviewer';
}
function get_short_key(user, pkg) {
  let flow_role = user_role(pkg);
  let key = [];
  let reg = pkg.registry || {};

  // flow_role
  key.push(flow_role);

  // registry.flow_active
  key.push(typeof reg.flow_active === 'undefined' ? 'active' : reg.flow_active ? 'active' : 'locked');
  // registry.flow_state
  key.push(typeof reg.flow_state === 'undefined' ? 'initial' : reg.flow_state);
  // registry.flow_level
  let fl = "0"; // 0 for undefined also
  if (reg.flow_level) {
    if (reg.flow_level < 0) {
      fl = "<0";
    } else if (reg.flow_level == 1) {
      fl = "1";
    } else {
      fl = "*";
    }
  }
  key.push(fl);
  // registry.flow_up
  key.push(reg.flow_up && "TRUE" || "FALSE");
  // registry.flow_down
  key.push(reg.flow_down && "TRUE" || "FALSE");
  // user.status
  let st = user && user.status;
  if (st && st == "initial") st = "ghost";
  key.push(st || '*');

  // AKA consultant_level
  let role;
  if (fl > 0) {
    let review_role = package_review_role(pkg, user) || ['*'];
    role = review_role[0];
  } else role = consultant_role(user, pkg);
  key.push(role);
  return key.join(':');
}
function consultant_role(user, pkg) {
  if (!user || !pkg) return undefined;
  let reg = pkg.registry || {},
    team = reg.collaborate_team,
    role;
  if (team) {
    if (_.find(team.primary, s => s.email_address == user.email_address)) role = "primary";else role = _.find(team.secondary, s => s.email_address == user.email_address) ? "secondary" : "readonly";
  } else {
    if (pkg.user && pkg.user.user_id && pkg.user.user_id == user.user_id || pkg.user_id && pkg.user_id == user.user_id || [ACCESS_LEVEL.OWN, ACCESS_LEVEL.WRITE].indexOf(_.get(pkg, "access.access_level")) > -1) role = "primary";else role = 'readonly';
  }
  return role;
}
const cmpBool = (val, b) => {
  return val === (b == 't');
};
function package_locking(user, pkg, context) {
  //clog("package_locking( user, pkg, "+ context + ")");
  if (!pkg) throw new Error("package_locking: no package");
  if (!context) throw new Error("package_locking: no context");
  if (!rules[context]) throw new Error("package_locking: context not found: " + context);
  let key = get_short_key(user, pkg);
  clog("package locking key:", key);
  let rule = find_rule_PL(key);
  clog("rule:", rule);
  let col = rules.cols[rule];
  clog("col:", col !== undefined ? col : "not found");
  clog("context:", context);
  if (typeof col == 'undefined') throw new Error("package_locking: no column for " + key);
  let r = {},
    s = rules[context];
  for (var k in s) {
    if (Array.isArray(s[k])) {
      r[k] = s[k][col];
      continue;
    }
    r[k] = {};
    for (var z in s[k]) {
      r[k][z] = s[k][z][col];
    }
  }
  //clog("permissions r before send menu rules:", r)

  // in case of set_key context nothing else is needed; the above is enough
  if (context == "set_key") {
    return r;
  }

  // SUB-485 #5 can't move if package is in a project
  // SUB-485 #5 Archive only possible for projects and loose packages.
  if (pkg.project_id) {
    if (context == "op") {
      r.move = false;
      r.archive = false;
    }
    if (r.action_menu && context == "ui") {
      r.action_menu.move = false;
      r.action_menu.archive = false;
    }
  }
  // SUB-557 send menu rules
  /*
   * doc: Assignment, routing and locking rules, SUB-364
   * tab: package send menu actions SUB-557
   * url: https://docs.google.com/spreadsheets/d/1geZQXcxTt8chjdcuviXpfcPfvYCasaxfAcs1gWnFSnk/edit?ts=5a38164b#gid=865688436
   *
   * Rules are checked here in a backward order, from right to left --
   * the later-matching rules overwrite the former-matching ones.
   */
  let reg = pkg.registry || {},
    flow_level = reg.flow_level || 0,
    flow_active = reg.flow_active || typeof reg.flow_active == "undefined",
    c_level = key.search(/\:secondary/) > -1 ? "secondary" : "primary",
    s_exists = !!((flow_level > 0 ? reg.review_team : reg.collaborate_team) || {}).secondary;

  // read-only team members in a prj SUB-2691
  if (key.search(/\:readonly/) > -1) {
    c_level = "readonly";
  }

  //clog("reg:", reg);
  let smr = _.reduce(rulesSM.list, (sm, r, ridx) => {
    let rulesFit = false,
      rlen = r.rule.length;
    for (let vidx = 0; vidx < rlen; vidx++) {
      let v = r.rule[vidx];
      //clog("check:", rulesSM.param[vidx], v);
      if (v === "" || v === "*") {
        rulesFit = true;
        continue;
      }
      rulesFit = false;
      switch (rulesSM.param[vidx]) {
        case "registry.flow_level":
          {
            let ops = v.match(/^([\<\>=]+)?(-?\d)$/);
            if (!ops) throw new Error("package_locking: unknown flow_level value " + v);
            let op = ops[1] || "=",
              _v = ops[2] || 0;
            switch (op) {
              case "<=":
                _v++;
              // eslint-disable-next-line no-fallthrough
              case "<":
                rulesFit = flow_level < _v;
                break;
              case ">=":
                _v--;
              // eslint-disable-next-line no-fallthrough
              case ">":
                rulesFit = flow_level > _v;
                break;
              case "=":
              case "==":
                rulesFit = flow_level == _v;
                break;
              default:
                throw new Error("package_locking: unknown flow_level op " + op);
            }
            break;
          }
        case "registry.flow_active":
          rulesFit = cmpBool(flow_active, v);
          break;
        case "registry.flow_state":
          {
            let arr = v.split(/[\s,]+/);
            rulesFit = arr.indexOf(reg.flow_state || "initial") != -1;
            break;
          }
        case "registry.flow_up":
          rulesFit = cmpBool(!!reg.flow_up, v);
          break;
        case "registry.flow_down":
          rulesFit = cmpBool(!!reg.flow_down, v);
          break;
        case "consultant_level":
          rulesFit = c_level == v;
          break;
        case "secondary_exists":
          rulesFit = cmpBool(s_exists, v);
          break;
        case "registry.open_items_present":
          rulesFit = cmpBool(!!reg.open_items_present, v);
          break;
        case "registry.flow_feedback_positive":
          rulesFit = cmpBool(reg.flow_feedback_positive, v);
          break;
        case "anything-to-assign":
          rulesFit = cmpBool(package_anything_to_assign(pkg), v);
          break;
        default:
          throw new Error("package_locking: unknown param to check:", rulesSM.param[vidx]);
      }
      if (!rulesFit) break;
    }
    clog("sm rule:", ridx + 1, rulesFit);
    if (!rulesFit) return sm;
    _.forEach(r.perm, (v, vidx) => {
      if (v === null) return;
      _.forEach(rulesSM.keys[vidx], k => {
        if (k && sm[k] === undefined) sm[k] = v;
      });
    });
    return sm;
  }, {});
  clog("smr:", smr);
  _.forEach(_.keys(smr), k => {
    let ka = k.split(/:/),
      l = ka.length - 1;
    if (!l) return;
    if (context == "op") {
      // in op context
      delete smr[k]; // clear and
      return; // skip action_menu:* send_menu:* items
    }

    smr[ka[0]] = smr[ka[0]] || {};
    smr[ka[0]][ka[1]] = smr[k];
    delete smr[k];
  });

  //clog("smr:", smr);
  if (context != "op") {
    r.action_menu = _.extend({}, r.action_menu, smr.action_menu);
    r.send_menu = _.extend({}, r.send_menu, smr.send_menu);
    delete smr.action_menu;
    delete smr.send_menu;
  }
  r = _.extend({}, r, smr);
  clog("r v1:", r);

  // take account permissions (can properties) into account
  // SUB-2278 Distributor Account type / subscription (previously known as "Extract to Package")
  let can = user.company && user.company.can;
  clog("can:", can);
  let payg_ml = _.get(pkg, "project.registry.mode", "") == "payg-multilevel";
  let send_menu = context == "op" ? r : r.send_menu || {};
  let action_menu = r.action_menu || {};

  // cannot assign, unassign & self-assign unless there is an assign permission or PAYG-multilevel project
  if (!can || !can.assign && !payg_ml || pkg.class === PACKAGE_CLASS.COMPOSITE) {
    ["assign", "unassign", "selfassign"].forEach(i => {
      send_menu[i] = false;
    });
  }
  // SUB-2278 can submit (above 0) and download to submit only if basic or total account
  // levels below 0 don't need this check - they can submit
  if (flow_level == 0 && (!can || !can.submit && !payg_ml)) {
    ["submit", "submitforreview", "submitforreview2", "submitforreview3"].forEach(i => {
      send_menu[i] = false;
    });
    if (context == "op") {
      r.download_to_submit = false;
    } else {
      ["download_to_submit_visible", "download_to_submit_enabled"].forEach(i => {
        action_menu[i] = false;
      });
    }
  }
  // cannot collaborate unless there is assign permission or PAYG-multilevel project or collaborate permission
  if (!can || !can.assign && !payg_ml && !can.collaborate) {
    ["collaborate_start", "collaborate_end"].forEach(i => {
      send_menu[i] = false;
    });
  }
  if (!can || !can.publish) {
    send_menu.publish = false;
  }

  // SUB-2285 S.O.M./architecture-specific: being able to upload a submitted document into a fresh-new empty package
  if (can && can.use_upload_split) {
    r.submittedoutside_upload_split = true;
    if (context == "op") {
      r.submittedoutside = true;
    }
  }
  if (pkg.class === PACKAGE_CLASS.CLOSEOUT) {
    if (context === 'op') {
      ['assign', 'unassign', 'submitforreview', 'propagate?up', 'propagate?down', 'propagate?up&down', 'unsubmit', 'download_to_submit'].forEach(k => {
        r[k] = false;
      });
    } else if (context === 'ui') {
      ['assign', 'unassign', 'return', 'submit', 'submitforreview', 'submitforreview2', 'submitforreview3', 'returnsubmit', 'resubmit', 'unsubmit'].forEach(k => {
        send_menu[k] = false;
      });
      ['download_to_submit_visible', 'download_to_submit_message', 'download_to_submit_enabled'].forEach(k => {
        action_menu[k] = false;
      });
    }
  }
  clog("r final:", JSON.parse(JSON.stringify(r)));
  return r;
}
function project_is_ghost(pd) {
  if (!pd || !pd.registry) return false;
  return pd.registry.flow_up ? true : false;
}

/**
 * Walk through the rule list and find first one that matches the assembled key
 */
const _find_rule_PL = _.memoize(key => {
  //clog( "find_rule_PL() key:", key );
  return rules.order.find(rule => {
    //clog("rule:", rule);
    return match_rule(key, rule, true);
  });
});
function find_rule_PL(key) {
  //clog( "find_rule_PL() key:", key );
  return _find_rule_PL(key + '');
}

/**
 * Check the assembled key against single rule
 */
function match_rule(key, rule, skip) {
  //clog( "match_rule: key:", key );
  let skeys = rule.split(':');
  let slen = skeys.length - 1;
  let rekeys = skeys.map((k, i) => {
    if (i == 0 && !skip) return k;
    let any = '.+' + (i == slen ? '' : '?');
    return k == '*' ? `${any}` : `(${k}|\\*)`;
  });

  //clog( "match_rule: rekeys:", rekeys );

  let tkey = '^' + rekeys.join(':') + '$';
  //clog ("tkey:", tkey)
  let isMatch = key.match(tkey);

  /*
  if (isMatch) {
      clog("orig: '%s'\nregx: '%s'\nmatch:", rule, tkey, isMatch);
  }
  */

  return isMatch != null;
}

/**
 * Walk through the rule list and find first one that matches the assembled key
 */
const _find_rule_SR = _.memoize(key => {
  let ctx = key.replace(/:.+$/, '');
  let rules = rulesSR[`${ctx}_order`];
  clog("ctx:", ctx);
  return rules.find(rule => {
    return match_rule(key, rule);
  });
});
function find_rule_SR(key) {
  return _find_rule_SR(key + '');
}
function get_SR_key(user, pkg, section, row) {
  //clog("get_SR_key:", user, pkg, section, row);

  let key = [];
  let reg = pkg.registry || {};

  // applies_to
  key.push(row ? 'row' : 'section');

  // flow_role:registry.flow_active:registry.flow_state:user.status
  key.push(get_short_key(user, pkg));

  // row.deleted && section.deleted
  // now via status = deleted|gone
  key.push(!row ? '*' : isDeleted(row) ? 'TRUE' : 'FALSE');
  key.push(isDeleted(section) ? 'TRUE' : 'FALSE');

  // row.flow_state
  key.push(!row ? '*' : row.flow_state || "initial");

  // created_here_or_below
  let flowLevel = reg.flow_level || 0;
  let srfol = (row ? row.flow_origin_level : section.flow_origin_level) || reg.flow_origin_level || 0;
  key.push(srfol <= flowLevel ? 'TRUE' : 'FALSE');
  let key_string = key.join(':');
  clog("package locking SR key:", key_string);
  return key_string;
}
function get_SR_col(user, pkg, section, row) {
  let key = get_SR_key(user, pkg, section, row);
  clog("SR key:", key);
  let rule = find_rule_SR(key);
  clog("rule:", rule);
  if (rule) {
    let col = rulesSR.cols[rule];
    clog("col:", col);
    return col;
  } else {
    clog("404 rule NOT FOUND");
    return null;
  }
}
function package_locking_SR_code(user, pkg, section, row) {
  let col = get_SR_col(user, pkg, section, row);
  if (col) {
    let code = rulesSR.case_code.by_col[col];
    clog("col: %s, case code: '%s'", col, code);
    return code;
  }
  return null;
}
function package_locking_SR_old_good(user, pkg, section, row) {
  let col = get_SR_col(user, pkg, section, row);
  if (col) {
    let key = row ? 'row' : 'section';
    let sr = rulesSR[key][col];
    clog("col: %s, %s:", col, key, sr);
    return sr;
  }
  return null;
}
function package_locking_SR(user, pkg, section, row) {
  return assembleModes(package_locking_modes(user, pkg, section, row), row);
}
function package_locking_path(user, pkg, path) {
  let splitPath = path.split("/");
  let cleanPath = splitPath.map(p => {
    return p.replace(/#.*$/g, '');
  });
  let cPath = cleanPath.join('/');
  let section = undefined,
    row = undefined;
  clog("check path '%s' -> '%s'", path, cPath);
  if (cPath.indexOf('package_data/sections') == 0) {
    let sectionId = splitPath[1].split('#')[1];
    section = (pkg.package_data.sections || []).filter(s => {
      return s.id == sectionId;
    }).shift();
    clog('section:', sectionId);
    if (section && cPath.indexOf('package_data/sections/rows') == 0) {
      let rowId = splitPath[2].split('#')[1];
      row = (section.rows || []).filter(r => {
        return r.id == rowId;
      }).shift();
    }
    clog('row:', row ? row.id : row);
  }
  let _rules;
  if (section) {
    // row- or section-level rules
    _rules = assembleModesPath(package_locking_modes(user, pkg, section, row), row);
  } else {
    // package-level rules apply
    _rules = {};
    _rules.path = package_locking(user, pkg, "set_key");
  }

  // check the requested path in cPath form against the rules
  if (_rules.path && _rules.path[cPath]) {
    return _rules.path[cPath];
  }
  clog("no direct path match");
  //clog("_rules", _rules);

  let pl = _rules.path;
  let r = pl[cPath];
  if ('undefined' == typeof r) {
    clog('no rule for path');
    // go up the path ladder, maybe there's an encompassing rule
    while (cleanPath.length > 1) {
      clog('check the parent path');
      cleanPath.pop();
      cPath = cleanPath.join('/');
      //clog("check path:", cPath)
      r = pl[cPath];
      //clog("r", r)
      if ('undefined' != typeof r) {
        return r;
      }
    }
    return false;
  }
  clog('result:', r);
  return r;
}
function package_locking_op(user, pkg, op) {
  let pLock = package_locking(user, pkg, 'op')[op];
  let res = 'undefined' === typeof pLock || pLock;
  return res;
}
function checkFactors(factors, factorsMatrix) {
  // this function returns true if a rule (given by factors) matches
  // the actual case (given by factorsMatrix), and returns false otherwise
  let res = true;
  if (!factors) {
    console.error("checkFactors: factors bad", factors);
    return false;
  }
  // check each factor, one by one;
  // cancel, if there is a mismatch
  // if no mismatch, it is a match, a success of the rule
  factors.forEach(function (f, i) {
    if (!res) return;
    // clog("checking factor", f, i)
    if (!f || f == "n/a") return; // skip empty/n/a cells
    if (f == "*") return; // any value matches

    let fname = caseToMode.factors[i];
    if (!fname) console.error("no factor for i =", i);
    if (!factorsMatrix.hasOwnProperty(fname)) {
      console.error("factorsMatrix key fail", fname, factorsMatrix);
      return;
    }
    let fval = factorsMatrix[fname];

    // some factor values may be a list of strings
    if (typeof f == "object") {
      if (f.indexOf(fval) == -1) {
        res = false;
      }
      return;
    }
    if (f == "TRUE" || f == "FALSE") {
      // undefined value is not TRUE and it is not FALSE
      if (typeof fval == 'undefined') return res = false;
      return res = f == "TRUE" ? fval : !fval;
    }
    if (f.match(/^([<>]=?)?0$/)) {
      if (typeof fval == 'undefined') return res = false;
      let fvalNumeric = fval || 0;
      if (f == 0 && fvalNumeric == 0) return;else if (f == 0 && fvalNumeric != 0) return res = false;
      if (f == ">0") {
        return res = fvalNumeric > 0;
      }
      if (f == "<0") {
        return res = fvalNumeric < 0;
      }
      if (f == ">=0") {
        return res = fvalNumeric >= 0;
      }
      if (f == "<=0") {
        return res = fvalNumeric <= 0;
      }
      return; // makes no sense, but let it stay here
    }

    if (f == "—") {
      if (typeof fval != "undefined") return res = false; // value should be undefined on dash
      else return;
    }

    // here should remain only exact string matching
    if (f != fval) res = false;
  });

  // clog("rule matched:", res)
  return res;
}
function sectionRowModes(cases, p) {
  let pkg = p && p.pkg;
  if (!pkg) throw new Error("sectionRowModes: no package", p);
  let preg = pkg.registry;
  if (!preg) {
    console.error("sectionRowModes package registry fail", p);
    preg = {
      mode: "trial"
    };
    pkg.registry = preg; // fix source registry
  }

  let row = p.row || {},
    section = p.section || {};
  let rowCreatedHereOrBelow = "",
    pflowLevel = preg.flow_level || 0,
    oflowLevel = (p.row ? row.flow_origin_level : section.flow_origin_level) || preg.flow_origin_level || 0;
  if (oflowLevel == pflowLevel) rowCreatedHereOrBelow = "equals";
  if (oflowLevel < pflowLevel) rowCreatedHereOrBelow = "less-than";

  //clog("preg:", preg)
  //clog("oflowLevel:", oflowLevel)
  //clog("pflowLevel:", pflowLevel)
  //clog("user", p.user);
  let _package_review_role = package_review_role(pkg, p.user) || [];
  let factorsMatrix = {
    "package project_id": pkg.project_id,
    "package flow_active": typeof preg.flow_active !== "undefined" ? preg.flow_active : true,
    "package flow_level": preg.flow_level || 0,
    "package flow_down": preg.flow_down,
    "package flow_state": preg.flow_state || "initial",
    "row.status": row.status,
    "row.flow_state": row.flow_state,
    "section.status": section.status,
    "row.linked": row.linked,
    "row_section.flow_origin_level vs. flow_level": rowCreatedHereOrBelow,
    "user.status": p.user && p.user.status,
    "row.flow_as_corrected": row.flow_as_corrected,
    "row_edited_after_review()": row_edited_after_review(row, preg) ? true : false,
    // SUB-2939 SUB-2451
    "flow_feedback_positive": preg.flow_feedback_positive,
    "package review_team_mode": preg.review_team_mode,
    "package_review_role": _package_review_role[0],
    "package_review_role_active": _package_review_role[2],
    "package class": pkg.class,
    "package review_mode_row": preg.review_mode_row
  };
  //clog("factorsMatrix", factorsMatrix);
  let mainMode = "",
    rulesetsModes = [];
  caseToMode[cases].forEach(function (rule, i) {
    if (mainMode) return;
    //clog("sectionRowModes check rule", rule.caseCode, i);
    if (checkFactors(rule.factors, factorsMatrix)) {
      clog("sectionRowModes main mode fired", rule.caseCode, rule.mainMode);
      if (rule.caseCode == "WTF") {
        console.error("WTF? Undefined row/section state case\n p = %O\nfactorsMatrix = %O", p, factorsMatrix);
        if (window && window.buildsite.config.sails.environment !== 'production' && alertify) {
          alertify.error("WTF? Undefined row/section state case; see dev console");
        }
      }
      mainMode = rule.mainMode;
      rule.rulesetsToCheck.forEach(function (rulesetId) {
        let ruleset = caseToMode.ruleSets[rulesetId],
          rulesetMode = "";
        ruleset.forEach(function (rulesetRule) {
          //clog( "check additional mode", rulesetRule.rulesetCode || rulesetId, "conditions")
          //if (rulesetMode) clog( "rulesetMode already present:", rulesetMode)
          if (rulesetMode) return;
          if (checkFactors(rulesetRule.factors, factorsMatrix)) {
            clog("sectionRowModes ruleset fired ", rulesetRule.rulesetCode || rulesetId);
            if (rulesetRule.rulesetCode) {
              rulesetMode = rulesetRule.rulesetCode;
              rulesetsModes.push(rulesetRule.rulesetCode);
            }
          }
        });
      });
    }
  });
  let r = [mainMode];
  Array.prototype.push.apply(r, rulesetsModes);
  //console.log("sectionRowModes:", r);
  return r;
}
function rear_new(r, registry) {
  /*  row      - row itself to check
   *  baseTime - check row's attr against this value
   *  uso      - check underscored mtime attrs only
   */
  const rie = (row, baseTime, uso) => {
    if (undefined === baseTime || baseTime < 0) return;
    // throw('internal error: no baseTime (' + (uso ? 'flow_last_return_epoch' : 'flow_last_return_from_above_epoch') + ')');

    const rx_check = uso ? /^__.+_mtime$/ : /_mtime$/;
    return !!_.find(_.keys(row), k => k.search(rx_check) > -1 && row[k] > baseTime);
  };
  if (undefined === registry) throw "internal error: no registry";
  let {
    flow_level,
    flow_last_return_epoch,
    flow_last_return_from_above_epoch
  } = registry;
  // SUB-2939 SUB-2451 this could eb useful for very old packages
  /*
  if (   flow_level <= 0 
      && !flow_last_return_from_above_epoch 
      && registry.flow_last_update 
      && registry.flow_last_update.action == "return" ) {
    flow_last_return_from_above_epoch = registry.flow_last_update.dt_epoch;
  }
  */

  return (flow_level || 0) > 0 ? rie(r, flow_last_return_epoch, 1) : rie(r, flow_last_return_from_above_epoch, 0);
}
function rear_former(r, registry) {
  if (registry && registry.flow_level > 0) {
    // reviewers' case
    // __flow_my_feedback_mtime is set within propagate?down
    if (!r.hasOwnProperty('flow_my_feedback_mtime')) return false;
    var feedback_mtime = r.flow_my_feedback_mtime;
    if (r.__subsection_mtime > feedback_mtime || r.__tags_mtime > feedback_mtime || r.__product_specified_mtime > feedback_mtime || r.__manufacturer_mtime > feedback_mtime || r.__product_submitted_mtime > feedback_mtime || r.__items_mtime > feedback_mtime || r.__notes_mtime > feedback_mtime || r.__status_mtime > feedback_mtime) return true;
    return false;
  }
  if (!r.hasOwnProperty('__flow_from_above_feedback_mtime')) return false;
  var feedback_mtime = r.__flow_from_above_feedback_mtime;
  if (r.subsection_mtime > feedback_mtime || r.tags_mtime > feedback_mtime || r.product_specified_mtime > feedback_mtime || r.manufacturer_mtime > feedback_mtime || r.product_submitted_mtime > feedback_mtime || r.items_mtime > feedback_mtime || r.notes_mtime > feedback_mtime || r.status_mtime > feedback_mtime) return true;
  return false;
}
function row_edited_after_review(r, registry) {
  return rear_new(r, registry);
  // huge intention to replace former code with new one
  // after testing phase rename rear_new or rear_former to row_edited_after_review and
  // remove this gate and rear_former or rear_new respectively
  /*
  const rf = rear_former(r, registry);
  const rn = rear_new(r, registry);
  //console.log("row_edited_after_review() =>", {rf, rn});
  if (rf !== rn) {
    console.log("row_edited_after_review new <> former", {rf, rn});
    alert( "row_edited_after_review new <> former" );
  }
  return rn;
  */
}

function assembleModes(modes, inRow) {
  let res = {};
  if (!modes) return {};
  let key = inRow ? "row" : "section";
  modes.forEach(mode => {
    if (!mode) return;
    let idx = srModes.modes[mode];
    let _rules = srModes[key][idx];
    _.keys(_rules).forEach(k => {
      if (!res[k]) res[k] = {};
      _.extend(res[k], _.omitBy(_rules[k], function (v, k) {
        return v == null;
      }));
    });
  });
  clog("assembleModes:", res);
  return res;
}
function assembleModesPath(modes) {
  let res = {
    path: {}
  };
  if (!modes) return {};
  modes.forEach(mode => {
    if (!mode) return;
    let idx = srModes.modes[mode];
    _.keys(srModes.path).forEach(k => {
      if (srModes.path[k][idx] !== null) res.path[k] = srModes.path[k][idx];
    });
  });
  clog("assembleModesPath:", res);
  return res;
}
function package_locking_modes(user, pkg, section, row) {
  let res = [];
  if (row) {
    clog("==> sectionRowModes() for row: " + row.id, "flow_state:", row.flow_state);
    res = sectionRowModes("rowCases", {
      user,
      pkg,
      section,
      row
    });
  } else {
    clog("==> sectionRowModes() for section: " + section.id);
    res = sectionRowModes("sectionCases", {
      user,
      pkg,
      section
    });
  }
  clog("package_locking_modes:", res.join(' '));
  return res;
}
function package_locking_modes_SR(modes, row) {
  return assembleModes(modes, row);
}
function pkgFeedbackSchema(pkg) {
  return _.get(pkg, "package_data.meta.feedback_schema", "base");
}
function isPositiveFeedback(fb, key) {
  if (!fb) return false;
  const schema = feedbackSchemes[key];
  if (!schema) throw `Unknown feedback schema key '${key}'`;
  const rf = _.find(schema.row.feedbacks, {
    code: fb
  });
  return rf ? rf.positive : false;
}
function feedbackAction(fb, key) {
  if (!fb) return 'revise';
  const schema = feedbackSchemes[key];
  if (!schema) throw `Unknown feedback schema key '${key}'`;
  const rf = _.find(schema.row.feedbacks, {
    code: fb
  });
  return rf ? rf.return_processing : '';
}
function feedbackOptions(key) {
  const schema = feedbackSchemes[key];
  if (!schema) throw `Unknown feedback schema key '${key}'`;
  return schema.row.feedbacks;
}
function feedbackPositiveDefault(key) {
  const schema = feedbackSchemes[key];
  if (!schema) throw `Unknown feedback schema key '${key}'`;
  return schema.row.default_positive_feedback;
}
function reviewResolutions(key) {
  const schema = feedbackSchemes[key];
  if (!schema) throw `Unknown feedback schema key '${key}'`;
  return schema.package.review_resolution_options;
}
function reviewResolutionLabel(r, key) {
  if (!r) return '';
  const schema = feedbackSchemes[key];
  if (!schema) throw `Unknown feedback schema key '${key}'`;
  const rro = _.find(schema.package.review_resolution_options, {
    code: r
  });
  return rro ? rro.label : '';
}
function isReviewResolutionPositive(r, key) {
  if (!r) return false;
  const schema = feedbackSchemes[key];
  if (!schema) throw `Unknown feedback schema key '${key}'`;
  const rro = _.find(schema.package.review_resolution_options, {
    code: r
  });
  return rro ? rro.positive : false;
}
function reviewFeedbackConflict(rPkg, rRow, key) {
  if (rPkg == rRow) return '';
  const schema = feedbackSchemes[key];
  if (!schema) throw `Unknown feedback schema key '${key}'`;
  const rKey = fb => fb ? 'positive' : 'negative';
  const fbKey = `pkg_${rKey(rPkg)}_row_${rKey(rRow)}`;
  return schema.feedback_conflict_message[fbKey] || null;
}

// https://builds.atlassian.net/browse/SUB-537 Design-team (A/E) review
const review_resolution_options = {
  "noexceptions": "No exceptions taken",
  "furnishascorrected": "Furnish as noted",
  "reviseresubmit": "Revise and resubmit"
};

// https://builds.atlassian.net/browse/SUB-537 Design-team (A/E) review
const review_resolution_order = ["noexceptions", "furnishascorrected", "reviseresubmit"];

// https://builds.atlassian.net/browse/SUB-537 Design-team (A/E) review
const review_resolution_positive = {
  "noexceptions": true,
  "furnishascorrected": true,
  "reviseresubmit": false
};
function checkReturnSubmitRules(pkg) {
  let opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  /* Additional rules for the Submit, Return, Submit & Return and Submit for review actions
   * rnsR1 - feedback is present on all recently submitted rows (ie. rows
   *         with 'feedback' flow_state), rule 1
   * rnsR2 - positive feedback is present on all 'about-to-submit-upwards'
   *         rows, part of rule 2
   * rnsR3 - rows with negative feedback from above have been modified at this level — i.e.
   *         the user has responded to negative feedback (for Submit)
   * rowsNotCompleted - ...
   * feedbackConflict - additional review resolution conflict SUB-537
  */
  let rnsR1 = true,
    rnsR2 = true,
    rnsR3 = true,
    row_count_live = 0,
    row_count_fb_negative = 0,
    pd = (pkg || {}).package_data || {},
    registry = pkg.registry,
    for_information = _.get(pd, 'meta.package_type', '') == "for_information",
    resolution = pd.review && pd.review.resolution,
    fb_schema = pkgFeedbackSchema(pkg),
    feedbackConflict = "",
    rowsNotCompleted = [],
    composite_package = pkg.class == PACKAGE_CLASS.COMPOSITE,
    review_mode_row_negative = Object.hasOwn(registry, 'review_mode_row') && !registry.review_mode_row,
    // SUB-2862 SUB-3011
    no_feedback_conflict_checking = for_information || composite_package && review_mode_row_negative;

  //clog( "checkReturnSubmitRules() composite_package:", composite_package );
  //clog( "checkReturnSubmitRules() review_mode_row_negative:", review_mode_row_negative );

  _.forEach(pd.sections, section => {
    // skip deleted sections
    if (section.status == "deleted") return;

    // iterate on rows
    _.forEach(section.rows, row => {
      // skip deleted & force-closed
      if (['deleted', 'closed'].indexOf(row.status) != -1) return;
      row_count_live++;
      if (!row.product_submitted && !row.notes && !row.items.length) {
        rowsNotCompleted.push({
          id: row.id,
          product_specified: row.product_specified
        });
      }

      // SUB-657 2.3 in case of IS package we assume positive feedback
      // SUB-2862 also, no checking for composite packages, unless row review is requested
      if (no_feedback_conflict_checking) return;
      let sec_row;
      let feedback_optional = false;
      clog("checkReturnSubmitRules() opts:", opts);
      if (opts.isSecondaryTeamReviewer && opts.user_id) {
        // SUB-2327 resolved row?
        if (row.flow_my_feedback && isPositiveFeedback(row.flow_my_feedback, fb_schema) && !row_edited_after_review(row, registry)) {
          // SUB-2327 no sec rev feedback is required
          feedback_optional = true;
        }
        if (opts.isSecondaryActive && row.secondary_stage) {
          sec_row = row.secondary_stage[opts.user_id];
        } else if (row.secondary) {
          //if at least one sec. reviewer left feedback and returned package
          sec_row = row.secondary[opts.user_id];
        } else sec_row = row; //if no team reviewers left feedbacks then .secondary field is absent
      } else sec_row = row;
      clog("checkReturnSubmitRules() row:", sec_row);
      if (row.flow_state == "feedback") {
        // we check user's direct feedback on 'feedback' state rows
        if (!feedback_optional && (!sec_row || !sec_row.flow_my_feedback)) {
          rnsR1 = false;
        }
      }
      if (row.flow_from_above_feedback && !isPositiveFeedback(row.flow_from_above_feedback, fb_schema) && !row_edited_after_review(row, registry)) {
        rnsR3 = false;
      }
      if (sec_row && sec_row.flow_my_feedback && !isPositiveFeedback(sec_row.flow_my_feedback, fb_schema)) {
        row_count_fb_negative++;
        rnsR2 = false;
      }
    });
  });

  // XXX we probably should not allow users do much with a package, where row_count_live is 0

  if (pkg && registry && registry.flow_level > 0 && pd.review && !no_feedback_conflict_checking) {
    // reviewer's feedback conflict,
    // see https://builds.atlassian.net/browse/SUB-537 comments for details
    if (resolution) {
      const pkgResolution = isReviewResolutionPositive(resolution, fb_schema);
      feedbackConflict = reviewFeedbackConflict(pkgResolution, !row_count_fb_negative, fb_schema);
      if (feedbackConflict !== '') {
        const resolutionLabel = reviewResolutionLabel(resolution, fb_schema);
        if (feedbackConflict === null) {
          feedbackConflict = `Overall resolution "${resolutionLabel}" conflicts with a row feedback.`;
        } else {
          feedbackConflict = feedbackConflict.replace(/%s/, resolutionLabel);
        }
      }
    } else {
      feedbackConflict = "You should provide a resolution before returning";
    }
  }
  clog("checkReturnSubmitRules():", {
    rnsR1,
    rnsR2,
    rnsR3,
    rowsNotCompleted,
    feedbackConflict
  });
  return {
    rnsR1,
    rnsR2,
    rnsR3,
    rowsNotCompleted,
    feedbackConflict
  };
}

// this is intentionally different from feedbackVisual in supaed/js/Row/renders.js
// originally from sulog/LogItemList/LogItem/index.jsx
const itemStatusVisual = {
  notreviewed: "Not reviewed",
  revise: "Revision",
  // logitem status, per SUB-376
  "?": ""
};
function logItemStatus(row, section, pkg) {
  let oreg = pkg.registry || {},
    fb_schema = pkgFeedbackSchema(pkg);
  //debug('pkg: %s, %j', pkg.status, oreg);
  //debug('row: %j', row);
  if (section.status) {
    return ['deleted', 'gone', 'closed'].indexOf(section.status) != -1 ? section.status : '?';
  }
  if (row.status) {
    return ['deleted', 'gone', 'closed'].indexOf(row.status) != -1 ? row.status : '?';
  }
  if ((oreg.collaborate_mode || "") == "consultant") return "collaborating";
  if (!oreg.flow_state) return 'unassigned';
  if (oreg.flow_state == 'initial') return 'unassigned';
  if (row.flow_state == 'deleted') return 'deleted';
  if (row.flow_from_above_feedback) {
    if (isPositiveFeedback(row.flow_from_above_feedback, fb_schema) && row_edited_after_review(row, oreg)) {
      return 'revise';
    }
    return row.flow_from_above_feedback;
  }
  if (row.flow_my_feedback) return row.flow_my_feedback;
  if (row.flow_state == 'assigned') return 'assigned';
  if (['feedback', 'reviewed'].indexOf(row.flow_state) != -1) return 'submitted';
  if (oreg.flow_state == 'self-assigned') return 'self-assigned';
  if (!row.flow_state) return 'unassigned';
  return '?';
}
;
function logItemStatusUi(row, section, pkg) {
  const status = logItemStatus(row, section, pkg);
  if (typeof itemStatusVisual[status] !== 'undefined') return itemStatusVisual[status];
  return status.charAt(0).toUpperCase() + status.slice(1);
}
function isPackageRowClosed(row, section, pkg) {
  if (!row || !section || !pkg) return false;
  if (row.status) {
    // 1. a force-closed (manually closed) log item (status=="closed")
    if (row.status == "closed") return true;
    // 2. a deleted log item or package row (status=="deleted")
    if (row.status == "deleted") return true;
  }
  let {
      flow_up,
      flow_state,
      flow_feedback_positive,
      flow_level,
      flow_last_submit_epoch
    } = pkg.registry || {},
    fb_schema = pkgFeedbackSchema(pkg);
  if (flow_level > 0) {
    return row.flow_my_feedback // PA's feedback is present
    && row.flow_my_feedback_mtime && flow_last_submit_epoch && row.flow_my_feedback_mtime < flow_last_submit_epoch // ...and it was set in a previous iteration, not in this one
    && isPositiveFeedback(row.flow_my_feedback, fb_schema) // and it's positive
    && !row_edited_after_review(row, pkg.registry) // and row hasn't been modified below
    ;
  }

  if (flow_up || row.flow_from_above_feedback) {
    // 3. a log item with positive feedback from above in chain. Only happens if package has been submitted up.
    // (flow_up is present and flow_from_above_feedback is positive and row hasn't been edited, SUB-1019)
    // See also: SUB-1222 In a given package, no item should close until all items have positive feedback
    if (isPositiveFeedback(row.flow_from_above_feedback, fb_schema) && flow_feedback_positive) {
      return !row_edited_after_review(row, pkg.registry);
    }
    /*  5. if the package is a "for information" submittal SUB-657, and it has
     *  been submitted up, it's items are closed too.
     *  (package_data.meta.package_type=="for_information" and flow_up is present)
    */
    if (((pkg.package_data.meta || {}).package_type || '') == "for_information") return true;
  }
  // 6. if the package has been unassigned by a person above in workflow (package flow_state=="unassigned").
  if ((flow_state || '') == "unassigned") return true;
  // 7. a package row in a deleted section (section.status=="deleted")
  // this row have status == "deleted" from logItemStatus(). processed at 2.

  return false;
}

// SUB-1294 SUB-1308
function packageSectionRowsCanBeForceClosed(section, pkg) {
  const section_status = section.status,
    registry = pkg.registry,
    disabled = section_status == "deleted" || registry.flow_up && !registry.flow_active,
    rows = section.rows;
  if (disabled) return [];
  return rows.filter(r => {
    // deleted rows can't be closed
    if (r.status == "deleted") return false;
    // closed rows can't be opened
    if (r.status == "closed") return false;
    // if row is not naturally closed, it can be force-closed
    return !isPackageRowClosed(r, section, pkg);
  });
}

// https://builds.atlassian.net/browse/SUB-554
function isLogitemClosed(el) {
  if (!el) return false;
  if (el.status) {
    const status = el.status;
    // 1. a force-closed (manually closed) log item (status=="closed")
    if (status == "closed") return true;
    // 2. a deleted log item or package row (status=="deleted")
    if (status == "deleted") return true;
    // revision or remove requested, or item rejected
    // or SUB-1019 item reviewed, but then edited by GC
    // logitem status will be "revise", if it has been edited since getting the positive feedback
    if (status == "revise" || status == "rejected" || status == "remove") return false;
  }
  // 3. a log item with positive feedback from above in chain. Applies if package has been submitted up.
  // (flow_up is present and flow_from_above_feedback is positive and row hasn't been edited, SUB-1019)
  // See also: SUB-1222 In a given package, no item should close until all items have positive feedback
  if (el.reg_flow_up && isPositiveFeedback(el.flow_from_above_feedback, el.feedback_schema) && el.reg_flow_feedback_positive) return true;
  /*  5. if the package is a "for information" submittal SUB-657, and it has
   *  been submitted up, it's items are closed too.
   *  (package_data.meta.package_type=="for_information" and flow_up is present)
  */
  if (el.reg_flow_up && el.package_type && el.package_type == "for_information") return true;
  // 6. if the package has been unassigned by a person above in workflow (package flow_state=="unassigned").
  if (el.reg_flow_state && el.reg_flow_state == "unassigned") return true;
  // 7. a package row in a deleted section (section.status=="deleted")
  // el have status == "deleted" from server. api/logitem.js:logItemStatus(). processed at 2.

  return false;
}
function logitemTumblerFlags(el, ro) {
  let closedStatus = el.status === "closed";
  let deletedStatus = el.status === "deleted";
  let hasFlowUp = el.reg_flow_up || el.reg_flow_up_to || el.reg_flow_reviewer_name;
  let disabledTumbler = ro || deletedStatus || hasFlowUp && !el.reg_flow_active || closedStatus && el.package_id && el.reg_flow_state && ["initial", "self-assigned"].indexOf(el.reg_flow_state) == -1;
  return {
    closedStatus,
    deletedStatus,
    disabledTumbler
  };
}
function logitemFilter(el, fil, specific) {
  // no filter
  if (!el || !fil || fil.length == 0) return true;
  let {
    specific_assignees,
    specific_sections
  } = specific || {};

  // filter by status
  if (el.status) {
    if (fil.indexOf("hide_deleted_items") > -1 && el.status == "deleted") return false;
    if (fil.indexOf("unassigned_items") > -1 && el.status !== "unassigned") return false;
    let isClosed = isLogitemClosed(el);
    if (fil.indexOf("closed_items_only") > -1 && !isClosed) return false;
    if (fil.indexOf("open_items_only") > -1 && isClosed) return false;
  }
  if (fil.indexOf("items_not_in_packages") > -1 && el.package_id) return false;
  let eltags = el.tags || {};
  if (fil.indexOf("no_tag_applied") > -1 && Object.keys(eltags).filter(t => t.search(/_mtime$/) == -1 && eltags[t]).length // active tags applied to item
  ) return false;
  if (fil.indexOf("specific_sections") > -1) {
    if (!specific_sections || specific_sections.length < 1) return false;
    if (specific_sections.indexOf(logitem_section_number(el)) == -1) return false;
  }
  if (fil.indexOf("specific_assignees") > -1) {
    if (!specific_assignees || specific_assignees.length < 1) return false;
    if (specific_assignees.indexOf(el.reg_flow_down_to) == -1) return false;
  }
  const skipping_fils = ["hide_deleted_items", "closed_items_only", "unassigned_items", "items_not_in_packages", "open_items_only", "specific_sections", "specific_assignees", "no_tag_applied"];
  // every filter tag besides *_items_only must be in global tags and element tags
  return fil.every(t => {
    return skipping_fils.indexOf(t) > -1 || eltags[t];
  });
}

// see Table of Rules in https://builds.atlassian.net/browse/SUB-896
function delete_row_rules(pkg, row) {
  if (!pkg) throw new Error("delete_row_rules: no package");
  if (!row) throw new Error("delete_row_rules: no row");
  let reg = pkg.registry || {};
  let resp = {};

  // row in unassigned/self-assigned pkg
  if (!reg.flow_down && !reg.flow_up) {
    return {
      leaves_traces: false,
      must_confirm: false
    };
  }
  if (!row.flow_state || row.flow_state == "initial") {
    return {
      leaves_traces: false,
      must_confirm: false
    };
  }
  if ((row.flow_origin_level || 0) < 0 && !!row.flow_from_above_feedback) {
    return {
      leaves_traces: true,
      must_confirm: false
    };
  }
  return {
    leaves_traces: true,
    must_confirm: true
  };
}

// SUB-907 SUB-1860
// we accept parallel temporarily here, because that's what we used to write;
// now we write 'consultant' into review_team_mode under SUB-907
//                         SUB-907       SUB-1860    SUB-1860
const review_team_modes = ['consultant', 'parallel', 'sequential'];

// - package_review_role(pkg, user)
// ret: [<level>, <mode>, <active>, <reviewer>]
// <level>:
//   primary | secondary | readonly
// <mode>:
//   consultant, parallel, sequential
// <active>: boolean
// <reviewer>: REVIEWER object from registry.review_team

function package_review_role(pkg, user) {
  if (!pkg || !user) {
    return undefined;
  }
  let {
    flow_level,
    flow_active,
    review_team_mode,
    review_team,
    __review_team
  } = pkg.registry;
  flow_level = flow_level || 0;

  //console.log( "package_review_role(): flow_level:", flow_level);

  if (flow_level !== 1) return undefined;
  const test_user = reviewer => {
    return reviewer.user_id == user.user_id;
  };
  // is this user a primary reviewer?
  if (review_team && review_team.primary) {
    let reviewer = review_team.primary.find(test_user);
    if (reviewer) return ["primary", review_team_mode, flow_active, reviewer];
  } else {
    /*if (!pkg.user || !pkg.user.user_id ) {
      console.log( "package_review_role():", "no review_team and no pkg.user.user_id to check if the user is primary");
    }*/
    if (pkg.user && pkg.user.user_id && pkg.user.user_id == user.user_id) return ["primary", null, flow_active];
  }
  if (!review_team && !__review_team) return undefined;

  // XXX a little risky:
  if (review_team_mode && review_team_modes.indexOf(review_team_mode) == -1) return undefined;

  // then maybe a secondary reviewer?
  if (review_team && review_team.secondary) {
    let reviewer = review_team.secondary.find(test_user);
    if (reviewer) {
      let active = true;
      if (!flow_active) {
        active = false;
      } else if (review_team_mode == 'sequential' || review_team_mode == 'parallel') {
        active = reviewer.active;
      }
      return ["secondary", review_team_mode, active, reviewer];
    }
  }
  return ["readonly"];
}
function reviewer_opts(pkg, user) {
  if (!pkg) throw "called reviewer_opts w/o package";
  let pd = pkg.package_data,
    rd = pd && pd.review,
    _rd = rd,
    _package_review_role = package_review_role(pkg, user),
    is_secondary_reviewer = _package_review_role && _package_review_role[0] == "secondary",
    is_secondary_reviewer_active = is_secondary_reviewer && _package_review_role[2],
    review_sk_path = "package_data/review/";

  // SUB-1113
  if (is_secondary_reviewer) {
    review_sk_path = "package_data/review/secondary_stage/" + user.user_id + "/";
    if (!rd || !rd.secondary_stage || !rd.secondary_stage[user.user_id]) {
      console.error("secondary_stage path error", rd);
      rd = {};
    } else {
      rd = pd.review.secondary_stage[user.user_id];
    }
  }

  // SUB-1114
  let pending_review_feedbacks = [],
    review_team = pkg.registry.review_team,
    secondary = _rd && _rd.secondary || [];
  if (review_team && review_team.secondary) {
    review_team.secondary.forEach(secrev => {
      // show the Pending feedback from ... line if no review.secondary[] data is present
      // or if review_team.secondary[] item is active.
      if (secrev.active || !secondary.some(rvs => rvs.user_id == secrev.user_id)) {
        pending_review_feedbacks.push(secrev.email_address);
      }
    });
  }
  return {
    rd,
    package_review_role: _package_review_role,
    is_secondary_reviewer,
    is_secondary_reviewer_active,
    review_sk_path,
    pending_review_feedbacks
  };
}
const arab = [1, 4, 5, 9, 10, 40, 50, 90, 100, 400, 500, 900, 1000];
const roman = ['I', 'IV', 'V', 'IX', 'X', 'XL', 'L', 'XC', 'C', 'CD', 'D', 'CM', 'M'];
function isRoman(r) {
  return !!r.match(/^(M{0,3})(D?C{0,3}|C[DM])(L?X{0,3}|X[LC])(V?I{0,3}|I[VX])$/i);
}
function romanToArab(s) {
  let str = s.toUpperCase();
  if (!isRoman(str)) {
    return null;
  }
  let ret = 0;
  let i = arab.length - 1;
  let len = str.length;
  let pos = 0;
  while (i >= 0 && pos < len) {
    if (str.substr(pos, roman[i].length) == roman[i]) {
      ret += arab[i];
      pos += roman[i].length;
    } else {
      i--;
    }
  }
  return ret;
}
function logitemSortBySubsection(lia, lib) {
  let a = lia.subsection,
    b = lib.subsection;
  if (!a && !b) return 0;
  if (!a) return -1;
  if (!b) return 1;
  if (a == b) return 0;
  let _a = a.split('.'),
    _b = b.split('.'),
    _la = _a.length,
    _lb = _b.length,
    m = Math.max(_la, _lb),
    ret = 0;
  for (let i = 0; i < m && !ret; i++) {
    let sa = _a[i],
      sb = _b[i];
    if (!sa) return -1;
    if (!sb) return 1;
    if (sa == sb) continue;
    let ina = !!sa.match(/^\d+$/),
      inb = !!sb.match(/^\d+$/);
    if (ina && inb) {
      ret = sa * 1 - sb * 1;
    } else if (!(ina || inb)) {
      ret = sa.localeCompare(sb);
      /*
      let ira = isRoman(sa),
          irb = isRoman(sb);
       if (ira && irb) {
          ret = romanToArab(sa) - romanToArab(sb);
      } else if (!(ira || irb)) {
          ret = sa.localeCompare(sb);
      } else {
          ret = ira ? -1 : 1;
      }
      */
    } else {
      ret = ina ? -1 : 1;
    }
  }
  return ret ? ret : _la - _lb;
}
function now_epoch() {
  return Math.trunc(Date.now() / 1000);
}
const isBefore = require('date-fns/is_before');
function project_packages_history(project) {
  let project_package_history = {};
  if (project.history) {
    project.history.forEach(h => {
      if (!h.package_id || ["return", "submit"].indexOf(h.event_type) == -1) return;
      if (!h.details || !h.details.message_id || !h.details.to) return;
      let dh = null;
      if (h.event_type == "return") {
        dh = _.extend({}, h, {
          "__received": get_received_for_return(project, h.package_id, h.datetime)
        });
      } else if (h.event_type == "submit") {
        dh = _.extend({}, h, {
          "__returned_review": get_returned_review_for_submit(project, h.package_id, h.datetime)
        });
      }
      if (project_package_history[h.package_id]) {
        project_package_history[h.package_id].push(dh);
      } else {
        project_package_history[h.package_id] = [dh];
      }
    });
    Object.keys(project_package_history).forEach(k => {
      project_package_history[k] = project_package_history[k].sort((a, b) => Date.parse(b.datetime) - Date.parse(a.datetime));
    });
  }
  let project_package_history_filtered = {};
  if (project.packages) {
    project.packages.forEach(pkg => {
      let _id = pkg.package_id;
      let ph = project_package_history[_id];
      if (!ph) return;
      let one_row_version = {};
      ph.forEach(phr => {
        let data = get_data_for_history(pkg, phr);
        let v = data.version,
          pkg_v = get_data_for_history(pkg).version;
        let pkg_meta = pkg.package_data && pkg.package_data.meta || {};
        if (!v || pkg_meta.revision && pkg_meta.revision == 1 || v >= pkg_v) return;
        if (one_row_version[v]) return;
        one_row_version[v] = 1;
        if (project_package_history_filtered[_id]) {
          project_package_history_filtered[_id].push(data);
        } else {
          project_package_history_filtered[_id] = [data];
        }
      });
    });
  }
  return project_package_history_filtered;
}
function get_received_for_return(project, package_id, datetime) {
  let hst = project.history;
  if (!hst) return null;
  let candidate = null;
  hst.forEach(h => {
    if (h.package_id != package_id) return;
    if (h.event_type != "submit") return;
    if (h.details && h.details && h.details.to && h.details.to[0]) return;
    if (!isBefore(h.datetime, datetime)) return; //later than event
    if (!candidate) {
      candidate = h;
    } else if (!isBefore(h.datetime, candidate.datetime)) {
      //latest from submits
      candidate = h;
    }
  });
  if (candidate) return candidate.datetime;
  return null;
}
function get_returned_review_for_submit(project, package_id, datetime) {
  let hst = project.history;
  if (!hst) return null;
  let candidate = null;
  hst.forEach(h => {
    if (h.package_id != package_id) return;
    if (h.event_type != "return") return;
    if (h.details && h.details && h.details.to && h.details.to[0]) return;
    if (isBefore(h.datetime, datetime)) return; //before than event
    if (!candidate) {
      candidate = h;
    } else if (isBefore(h.datetime, candidate.datetime)) {
      //oldest from submits
      candidate = h;
    }
  });
  if (candidate) return candidate.datetime;
  return null;
}
function get_data_for_history(pkg, package_history_record) {
  let phr = package_history_record,
    preg = pkg.registry || {},
    pd = pkg.package_data || {},
    meta = pd.meta || {},
    r = {
      pkg: pkg
    },
    flow_last_update = preg.flow_last_update || {};
  if (phr) {
    let phrd = phr.details || {};
    let vr = (phrd.package_title || "").match(/, (v\d+)$/);
    r.version = vr && vr[1];
    r.linkTo = "/app/sent/" + phrd.message_id;
    r.titleRev = phrd.package_title;
    r.last = phr.datetime;
    r.recipient = phrd.to && phrd.to[0];
    r.assignedTo = null;
    r.assignedDate = null;
    r.needByDate = null;
    r.receivedDate = phr.__received;
    r.submittedTo = null;
    r.submittedDate = phr.datetime;
    r.secondaryReviewer = [];
    r.returnedDate = phr.__returned_review;
    if (phr.event_type == "return") {
      r.status = "Returned";
      r.assignedTo = prettyName(phrd.to_name, phrd.to && phrd.to[0]);
    }
    if (phr.event_type == "submit") {
      r.status = "Submitted";
      r.submittedTo = prettyName(phrd.to_name, phrd.to && phrd.to[0]);
    }
  } else {
    let v = meta.revision;
    r.version = v ? "v" + (v < 10 ? "0" : "") + v : null;
    r.linkTo = "/app/package/" + pkg.package_id;
    r.titleRev = pkg.title_rev;
    r.last = pkg.auto_mtime || pkg.auto_ctime;
    r.recipient = flow_last_update.to ? flow_last_update.to.email : null;
    r.assignedTo = assignedToFromPkg(pkg);
    r.assignedDate = assignedDateFromPkg(pkg);
    r.needByDate = meta.need_by_date;
    r.receivedDate = preg.flow_last_submit;
    r.submittedTo = prettyName(preg.flow_reviewer_name, preg.flow_up_to);
    r.submittedDate = preg.flow_submitforreview_date;
    r.duetoDesignDate = meta.due_to_design_date;
    r.secondaryReviewer = secondaryReviewer(pkg);
    r.dueDate = meta.due_date;
    r.returnedDate = preg.flow_review_return_date;
    r.status = PackageStatus(pkg);
  }
  return r;
}
function assignedToFromPkg(pkg) {
  let preg = pkg.registry || {};
  if (PackageStatus(pkg) == "Collaborating") {
    let cts = preg.collaborate_team && preg.collaborate_team.secondary;
    return cts && cts[0] && prettyName(cts[0].name, cts[0].email_address) || "";
  }
  return prettyName(preg.flow_assignee_name, preg.flow_down_to);
}
function assignedDateFromPkg(pkg) {
  let preg = pkg.registry || {};
  if (PackageStatus(pkg) == "Collaborating") {
    let cts = preg.collaborate_team && preg.collaborate_team.started;
    if (cts) return cts;
  }
  return preg.flow_assigned_date;
}
function secondaryReviewer(pkg) {
  let {
    review
  } = pkg.package_data;
  if (!review) return [];
  let {
      secondary
    } = review || {},
    _s = secondary || [];
  return _s;
}
function PackageStatus(p) {
  const {
    package_data,
    registry
  } = p;
  if ((!registry.flow_level || registry.flow_level < 1) && registry.collaborate_mode) return "Collaborating";
  if (!registry.flow_state || registry.flow_state == "initial") {
    // Collaborating or Unassigned
    return "Unassigned";
  }

  // Reviewed packages: review resolution
  if ((registry.flow_state == "reviewed" || registry.flow_state == "approved") && package_data.review && package_data.review.resolution) {
    return reviewResolutionLabel(package_data.review.resolution, pkgFeedbackSchema(p));
  }
  if (registry.flow_state == "feedback") return "Submitted";
  if (registry.flow_state == "feedback-outside") return "Submitted";
  return capitalize(registry.flow_state);
}

/***
 return a list of project reviewers as objects
    name     - full name or null
    email    - reviewer email address
    role     - primary/secondary
    project  - reviewer is a project defined one
    user_id  - optional, only for reviewers obtained from packages
*/
function getProjectReviewers(project) {
  if (!project) return null;
  let revList = [];
  let {
    packages,
    project_data: {
      meta
    },
    origin_packages
  } = project;
  let revs_list = {};
  reviewerRoles.forEach(r => {
    let rev = meta[r + "_reviewer"];
    if (rev) {
      let n = _.reduce(["first", "last"], (a, f) => {
        if (rev["name_" + f]) a.push(rev["name_" + f]);
        return a;
      }, []);
      revs_list[rev.email_address] = 1;
      revList.push({
        name: n.join(" "),
        email: rev.email_address,
        project: true,
        role: r
      });
    }
  });
  let pkgs = [].concat(packages || []).concat(origin_packages || []);
  let pkg_revs = _.reduce(pkgs, (r, pkg) => {
    let a = ((pkg.registry || {}).flow_up_to || "").toLowerCase();
    if (a && !revs_list[a]) {
      revs_list[a] = 1;
      r.push({
        name: pkg.registry.flow_reviewer_name || null,
        email: pkg.registry.flow_up_to,
        user_id: pkg.registry.flow_up_user_id,
        role: "primary"
      });
    }
    let {
      secondary
    } = pkg.package_data.review || {};
    _.forEach(secondary, s => {
      a = (s.email_address || "").toLowerCase();
      if (a && !revs_list[a]) {
        revs_list[a] = 1;
        r.push({
          name: s.by || null,
          email: s.email_address,
          user_id: s.user_id,
          role: "secondary"
        });
      }
    });
    return r;
  }, []);
  return revList.concat(pkg_revs);
}
function package_sections(obj, pdf) {
  if (!obj || !obj.sections) return null;
  if (!pdf) return obj.sections;
  let _sections = _.filter(obj.sections, _section => (_section.status || "") != "deleted");
  return _sections.length ? _.cloneDeep(_sections) : null;
}
;
function package_items(pkg_object, opts) {
  opts = opts || {};
  let obj = pkg_object.package_data || {};
  let pdf = opts.pdf;
  let package_rows = function () {
    let sections = package_sections(obj, pdf);
    let sectionsNames = [],
      subSectionsNames = [];
    let _list = [];
    _.forEach(sections, _section => {
      if (opts.not_deleted && !opts.include_removed_parents && _section.status == 'deleted') return;
      if (pdf && _section.section_name) sectionsNames.push(_section.section_name);
      _.forEach(_section.rows, _row => {
        if (!pdf || ["deleted"].indexOf(_row.status) == -1) {
          let src;
          if (pdf) {
            if (_row.subsection) subSectionsNames.push(_row.subsection);
            src = _.cloneDeep(_row);
            src.extra = {
              section_name: _section.section_name
            };
          } else {
            src = _row;
          }
          _list.push(src);
        }
      });
    });
    let soDocs = (pkg_object.package_data || {}).documents;
    if (soDocs) {
      let src;
      if (pdf) {
        src = _.cloneDeep(soDocs);
        src.extra = {
          section_name: sectionsNames.join("; ")
        };
        src.subsection = subSectionsNames.join(", ");
        src.product_submitted = "Submittal documentation";
      } else {
        src = soDocs;
      }
      src.so = true; // submitted outside
      _list.push(src);
    }
    if (opts.ro) {
      let roDocs = (pkg_object.package_data || {}).returned_documents;
      if (roDocs) _list.push(roDocs);
    }
    return _list;
  };
  let rows = package_rows();
  if (!rows || !rows.length) {
    return [];
  }
  let _list = _.reduce(rows, (arr, _row) => {
    // both for not reviewed yet and completed review by secondary
    let _r = _row;
    if (opts.secondary_stage) {
      if (_row.secondary_stage && _row.secondary_stage[opts.user_id]) {
        _r = _row.secondary_stage[opts.user_id] || {};
      }
      //else if (_row.secondary && _row.secondary[opts.user_id]) { _r = _row.secondary[opts.user_id] || {}; }
    }

    if (opts.not_deleted && !opts.include_removed_parents && _r.status == 'deleted') return arr;
    if (!(_r.items || []).length) return arr;
    return arr.concat(_r.items);
  }, []);
  return _list;
}
function package_anything_to_assign(pkg) {
  if (!pkg || !pkg.package_data) return false;
  let assignedRows = false;
  _.forEach(package_sections(pkg.package_data), _section => {
    // handle deleted sections
    if (_section.status == 'deleted') {
      return;
    }
    _.forEach(_section.rows, _row => {
      if (['deleted', 'closed'].indexOf(_row.status) != -1 || _row.flow_state == 'reviewed') return;
      assignedRows = true;
      return false;
    });
    if (assignedRows) return false;
  });
  return assignedRows;
}
function unassignedOrphanFlags(preg) {
  if (!preg || !preg.flow_state) return {};
  let f = {
    isUnassigned: preg.flow_state == "unassigned",
    /* isUnassigned == true - package is unassigned */
    isUnsubmitted: preg.flow_state == "unsubmitted",
    /* SUB-1149 Unsubmitted package? */
    orphan_deleted: preg.flow_state == "orphan-deleted" /* SUB-978 orphan_deleted == true - reviwer package view for deleted by creator */
  };

  if (Object.keys(f).some(k => f[k])) f.is_unassigned_or_orphan = true;
  return f;
}
function unassignedOrphanNote(inp) {
  if (!inp) return "";
  let obj = inp; // defined by params
  if (inp.flow_state) obj = unassignedOrphanFlags(inp); // get from registry
  let {
    isUnassigned,
    isUnsubmitted,
    orphan_deleted
  } = obj;
  let out = "";
  if (isUnassigned) out += "Unassigned";
  if (isUnsubmitted) out += "Assigned elsewhere";
  if (orphan_deleted) out += "Deleted";
  return out;
}
function getItemNumStr(item_num) {
  return item_num ? ("000" + item_num).substr(-4) : "";
}
function outsideSubmittedDocuments(o) {
  return (o.package_data || {}).documents;
}
function getFeedbackLabel(schema, feedbackValue, feedbackNotes) {
  //console.log("getFeedbackLabel()", schema, feedbackValue, feedbackNotes)
  if (!schema || !feedbackValue) return undefined;
  let fb_menu = feedbackOptions(schema),
    fb_item = _.find(fb_menu, {
      code: feedbackValue
    }),
    withNotes = feedbackNotes !== undefined && feedbackNotes !== null;
  if (!fb_item) return undefined;
  return fb_item.ui.length == 1 ? fb_item.ui[0].item_name : _.get(_.find(fb_item.ui, {
    with_notes: withNotes
  }), 'item_name');
}

//used in downloads and exports
function getRowFeedbackTitle(row, pkg) {
  let schema = pkgFeedbackSchema(pkg),
    fb = row.flow_my_feedback,
    fbNotes = row.flow_my_feedback_notes;
  if (row.flow_from_above_feedback !== undefined) {
    fb = row.flow_from_above_feedback;
    fbNotes = row.flow_from_above_feedback_notes;
  }
  return getFeedbackLabel(schema, fb, fbNotes);
}

//used in downloads and exports
function getRowFeedbackNotes(row) {
  return typeof row.flow_from_above_feedback !== "undefined" ? row.flow_from_above_feedback_notes : row.flow_my_feedback_notes;
}
function get_rfi_key(rfi) {
  let key = [];
  let {
    flow_active,
    flow_state,
    flow_up,
    flow_down
  } = rfi.registry || {};

  // registry.flow_active
  key.push(flow_active && "TRUE" || "FALSE");
  // registry.flow_state
  key.push(flow_state);

  // registry.flow_up
  key.push(flow_up && "Y" || "N");
  // registry.flow_down
  key.push(flow_down && "Y" || "N");
  // rfi_data.response.body or rfi_data.response.documents
  key.push((_.get(rfi, 'rfi_data.response.body') || _.get(rfi, 'rfi_data.response.documents', []).length) && "Y" || "N");
  return key.join(':');
}
const _rfiTopKeys = _.keys(rulesRfi).filter(k => ['cols', 'order'].indexOf(k) < 0);
const _rfiKeys = _.reduce(_rfiTopKeys, (a, m) => {
  a[m] = _.keys(rulesRfi[m]);
  return a;
}, {});
const _rfi_locking = _.memoize(key => {
  let rfil = {};
  _.forEach(_rfiTopKeys, tk => rfil[tk] = {});
  return _.filter(rulesRfi.order, rule => {
    return match_rule(key, rule, true);
  }).reduce((a, r) => {
    const col = rulesRfi.cols[r];
    clog("col:", col);
    _.forEach(_rfiTopKeys, tk => {
      _.forEach(_rfiKeys[tk], k => {
        //clog({tk, k, col}, `'${rulesRfi[tk][k][col]}'`);
        if (a[tk][k] === undefined && rulesRfi[tk][k][col] !== "" && rulesRfi[tk][k][col] !== undefined) a[tk][k] = rulesRfi[tk][k][col];
      });
    });
    return a;
  }, rfil);
});
function rfi_locking(rfi) {
  const key = get_rfi_key(rfi) + '';
  clog("rfi key:", key);
  return _rfi_locking(key);
}
const {
  policy_defaults
} = require('./security-policies');
function checkGetPolicy(name, user, company, configOnly) {
  let dPolicy = typeof sails === 'object' && sails.config.submittal.policy || typeof window !== "undefined" && window.buildsite.config.policy || {};
  if (configOnly) return dPolicy;
  let uPolicy = _.get(user, 'profile.policy', {}),
    cPolicy = _.get(company || user.company, 'profile.policy', {}),
    tPolicy = _.defaultsDeep({}, uPolicy, cPolicy, dPolicy, policy_defaults());

  // name is attribute path like 'attempts' or 'mfa.ttl' or even empty/null to return full policy object
  return name ? _.get(tPolicy, name) : tPolicy;
}
function getRfiTooltip(rfi_num, title) {
  return "RFI-" + String(rfi_num).padStart(4, '0') + ' ' + title;
}
const timingDays = {
  'standard': 10,
  'expedited': 5,
  'urgent': 2
};
const timing_options = [{
  value: 'standard',
  label: `Standard (${timingDays['standard']} business days)`
}, {
  value: 'expedited',
  label: `Expedited (${timingDays['expedited']} business days)`
}, {
  value: 'urgent',
  label: `Critical (${timingDays['urgent']} business days)`
}];
function costImpact(val) {
  let cost_impact = val;
  if (cost_impact && cost_impact.value) {
    let val = cost_impact.value < 0 ? '-' : '+';
    if (cost_impact.currency_code === 'USD') val += ' $ ';
    val += Intl.NumberFormat('en-US').format(Math.abs(cost_impact.value));
    cost_impact = val;
  } else {
    cost_impact = 'None';
  }
  return cost_impact;
}
function timeImpact(val) {
  let time_impact = val;
  if (time_impact && time_impact.value) {
    let val = time_impact.value < 0 ? '' : '+ ';
    val += time_impact.value + " " + time_impact.units;
    time_impact = val;
  } else {
    time_impact = 'None';
  }
  return time_impact;
}
function viewspec_url(row, pkg) {
  return ["/viewspec", pkg.package_id, row.logitem_id || row.id, row.src_spec.timestamp].filter(k => k).join("/");
}
module.exports = {
  project_packages_history,
  get_data_for_history,
  user_role,
  project_is_ghost,
  isDeleted,
  is_user_registered,
  checkReturnSubmitRules,
  reviewResolutions,
  reviewResolutionLabel,
  isReviewResolutionPositive,
  isPositiveFeedback,
  pkgFeedbackSchema,
  feedbackAction,
  feedbackOptions,
  feedbackPositiveDefault,
  logItemStatus,
  logItemStatusUi,
  isPackageRowClosed,
  isLogitemClosed,
  logitemTumblerFlags,
  packageSectionRowsCanBeForceClosed,
  logitemFilter,
  logitemSortBySubsection,
  cleanPath,
  now_epoch,
  delete_row_rules,
  row_edited_after_review,
  prettyName,
  capitalize,
  reviewerRoles,
  getProjectReviewers,
  unassignedOrphanFlags,
  unassignedOrphanNote,
  consultant_role,
  package_locking,
  package_locking_section_code: package_locking_SR_code,
  package_locking_section: package_locking_SR,
  package_locking_row_code: package_locking_SR_code,
  package_locking_row: package_locking_SR,
  package_locking_path,
  package_locking_test: package_locking_modes,
  package_locking_section_modes: package_locking_modes,
  package_locking_row_modes: package_locking_modes,
  package_locking_modes_section: assembleModes,
  package_locking_modes_row: m => assembleModes(m, true),
  package_locking_assemble_modes: assembleModes,
  package_locking_op,
  package_review_role,
  reviewer_opts,
  package_items,
  getItemNumStr,
  outsideSubmittedDocuments,
  getFeedbackLabel,
  getRowFeedbackTitle,
  getRowFeedbackNotes,
  rfi_locking,
  checkGetPolicy,
  getRfiTooltip,
  costImpact,
  timeImpact,
  timingDays,
  timing_options,
  viewspec_url
};