"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _alertify = _interopRequireDefault(require("alertify.js"));
if (!window.alertify) {
  // https://alertifyjs.org/
  // discussed with Paul on devcall Tue 15 May 2018
  window.alertify = _alertify.default.closeLogOnClick(true).delay(30000);
}
/*
if (!window.buildsite) {
  require('../mla-c/0.3/main.js')();
}
*/
var api = buildsite.api();
api.on('offline', function (err) {
  //alertify.error("No connection to server");
});
api.on('online', function (err) {
  //alertify.success("Connection to server restored");
});
api.on('mlac.error', function (err) {
  if (api.online && err) {
    console.log('api:error event', err.errors);
    if (Array.isArray(err.errors)) {
      err.errors.forEach(e => {
        handle_error(e);
      });
    } else {// this branch is totally useless
      /*
      let message = "Something's wrong";
      if (err.type == "server") {
        message = "Server failed to handle a request";
      } else if (err.type == "network") {
        message = "Can't connect to server";
      }
      if (err.err) { message += ": " + err.err; }
      */
      //alertify.error(message);
    }
  }
});
function handle_error(e) {
  if (e && typeof e.code != 'undefined') {
    console.log('handle_error:', e.code);
    /*
    const field = e.field || '';
    const message = e.message || '';
    */
    //alertify.error(e.code + ": " + field + " " + message);
  } else if (!e) {
    console.error("Unhandled error");
    _alertify.default.error("Unhandled error");
  } else if (e == 'access-denied') {
    _alertify.default.error("Sorry, you can't do that.");
  } else {
    console.log('handle_error:', e);
    e && _alertify.default.error(e);
  }
}