"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = user_auth;
// see https://docs.google.com/document/d/1WyFXaNFBeqq3yx-Q5VcrveBpcZZEtZq5cbqrO4bLZdA/edit#
// the MLA-client section

class UserAuth {
  constructor(api) {
    this.type = "user";
    this.api = api;
    this.user = null; // ???
  }

  authenticate(email, password, token, type, isFallback) {
    let obj = this;
    let dfd = $.Deferred();
    let data = {
      skipErrorHandling: true
    };
    if (isFallback) {
      data = {
        ...data,
        type,
        email_address: email,
        password
      };
    } else data = _.assign(data, {
      email_address: email,
      ...(token ? {
        token,
        type,
        password
      } : {
        password
      })
    });
    obj.api.queue_req({
      op: "authenticate",
      type: this.type,
      id: "_",
      data
    }).done(data => {
      if (data) {
        if (data.status == "OK") {
          let u = buildsite.currentUser();
          u.set_data(data.data);
          u.start_polling();
          dfd.resolve(data);
        } else {
          dfd.reject(data);
        }
        return;
      }
      dfd.reject({
        status: "FAIL",
        err: ["No data"]
      });
    }).fail((xhr, status, err) => {
      dfd.reject({
        status,
        errors: err
      });
    });
    return dfd.promise();
  }
}
function user_auth() {
  let api = this;
  let ua = new UserAuth(api);
  return ua;
}