"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
const uemail = () => {
  return buildsite.user().data ? buildsite.user().data.email_address : null;
};
const rAC = email => {
  if (vgo && window.visitorGlobalObject) {
    if (email) vgo('setEmail', email);
    try {
      const visitor = window.visitorGlobalObject._getVisitor();
      visitor.referrer = visitor.url;
      let page = window.location.href;
      if (page.search(/\/ro/)) {
        page = page.replace(/\/[0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12}$/, ''); // remove invite code from last path part if ends with uuid4
      }

      visitor.url = page;
      vgo('process');
    } catch (e) {}
  }
};
function logPageView() {
  if (!buildsite || 'function' !== typeof buildsite.user) {
    return;
  }
  if (uemail()) {
    rAC(uemail());
  } else {
    buildsite.user().open().always(() => {
      rAC(uemail());
    });
  }
}
var _default = {
  logPageView
};
exports.default = _default;