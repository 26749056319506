"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.imageReducer = imageReducer;
exports.img = img;
function img(p) {
  return require("../../images/2023redesign/" + p);
}
function imageReducer(desktopName, mobileName) {
  return window.screen.width > 767 ? img(desktopName) : img('mobileScreen/' + mobileName);
}