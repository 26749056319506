"use strict";

var _bl_base = require("./bl_base");
////
/*
class blPublic extends blBase {
}
*/
module.exports = function (user) {
  if (window && window.buildsite && window.buildsite.bl) return window.buildsite.bl;
  let bl = new _bl_base.blBase();
  bl.init(user);
  if (window) window.buildsite = _.assign(window.buildsite || {}, {
    bl
  });
  return bl;
};